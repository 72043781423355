import React from 'react'
import './servicebox.css'

import serviceIcon1 from '../../assets/images/services-icon01.svg'
import serviceIcon2 from '../../assets/images/services-icon04.svg'
import serviceIcon3 from '../../assets/images/services-icon03.svg'

function ServiceBox({ servicesData }) {
    // console.log('servicesData',servicesData);
    return (
        <div className='row'>
            <div className='col'>
                <div className='service-box'>
                    <span className='icon'><img src={serviceIcon1} /></span>
                    <h2>{servicesData?.[1]?.heading}</h2>
                </div>
            </div>
            <div className='col'>
                <div className='service-box'>
                    <span className='icon'><img src={serviceIcon2} /></span>
                    <h2>{servicesData?.[2]?.heading}</h2>
                </div>
            </div>
            <div className='col'>
                <div className='service-box'>
                    <span className='icon'><img src={serviceIcon3} /></span>
                    <h2>{servicesData?.[3]?.heading}</h2>
                </div>
            </div>
        </div>
    )
}

export default ServiceBox