import React, { useContext, useEffect, useState } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import profileIcon1 from '../../assets/images/profile-svg01.svg';
import profileIcon2 from '../../assets/images/profile-svg02.svg';
import profileIcon3 from '../../assets/images/profile-svg03.svg';
import profileIcon4 from '../../assets/images/profile-svg04.svg';
import profileIcon5 from '../../assets/images/profile-svg07.svg';
import profileIcon6 from '../../assets/images/profile-svg08.svg';
import './myprofile.css'
import ChangePasswordPopup from '../../components/menudropdoenpopup/ChangePasswordPopup';
import StackChnage from '../../components/menudropdoenpopup/StackChnage';
import { PopupContext } from '../../components/LoginPopup/LoginPopupContext';

function MyProfile() {
    const [changePassword, setChangePassword] = useState(false);
    const [stackChange, setStackChange] = useState(false);
    const handleClose = () => {setChangePassword(false); setStackChange(false);}
    // const { loggedIn } = useContext(PopupContext);
    // const [isLoggedIn, setIsLoggedIn] = useState(() => {
    //     const storedValue = sessionStorage.getItem('loggedIn');
    //     return storedValue === "true";
    //   });
    // const navigate = useNavigate();

    // useEffect(()=>{
    //     if(!isLoggedIn || loggedIn){
    //         navigate('/')
    //     }
    // },[isLoggedIn, loggedIn])

    const handleShow = (type) => {
        if(type === 'changepasw'){
            setChangePassword(true);
            setStackChange(false);
        }else if(type === 'stackchange'){
            setChangePassword(false);
            setStackChange(true);
        }else{
            setChangePassword(false);
            setStackChange(false);
        }
     }
  return (
    <>
        <>
            <div className='container-fluid'>
                <div className='profile-menu-list'>
                    <Link onClick={()=>{handleShow('changepasw')}}><span className='icon'><img className="img-fluid" src={profileIcon1} alt=""/></span> Change Password<i className="fa-solid fa-chevron-right"></i></Link>
                    <Link to='/myAccountStatment'><span className='icon'><img className="img-fluid" src={profileIcon2} alt=""/></span> Account Statement<i className="fa-solid fa-chevron-right"></i></Link>
                    <Link to='/MyPlStatement'><span className='icon'><img className="img-fluid" src={profileIcon3} alt=""/></span> P/L Statement<i className="fa-solid fa-chevron-right"></i></Link>
                    <Link to='/mybets'><span className='icon'><img className="img-fluid" src={profileIcon4} alt=""/></span> My Bets<i className="fa-solid fa-chevron-right"></i></Link>
                    <Link to='/myGameReport'><span className='icon'><img className="img-fluid" src={profileIcon5} alt=""/></span> Game Report<i className="fa-solid fa-chevron-right"></i></Link>
                    <Link onClick={()=>{handleShow('stackchange')}}><span className='icon'><img className="img-fluid" src={profileIcon6} alt=""/></span> Set Button Stake<i className="fa-solid fa-chevron-right"></i></Link>
                </div>
            </div>
            <ChangePasswordPopup isopen={changePassword} handleClose={handleClose} />
            <StackChnage isopen={stackChange} handleClose={handleClose} />
        </>
    </>
  )
}

export default MyProfile