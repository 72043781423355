import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Popup from "../common/popup/Popup";
import { useEffect } from "react";

function StackChnage({ isopen, handleClose }) {
  const token = sessionStorage.getItem("token");
  const stackLists = sessionStorage.getItem("stackList");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [stackList, setStackList] = useState();

  const [amount1, setAmount1] = useState("100");
  const [amount2, setAmount2] = useState("500");
  const [amount3, setAmount3] = useState("1000");
  const [amount4, setAmount4] = useState("5000");
  const [amount5, setAmount5] = useState("5000");

  useEffect(() => {
    getStackListAPI();
  }, []);

  const getStackListAPI = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      whiteLabelName: "jerambro.com",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/getStakeLabes`, requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        paresData.data.stakeArray.map((item,index)=>(
            index === 0? setAmount1(item.value):'',
            index === 1? setAmount2(item.value):'',
            index === 2? setAmount3(item.value):'',
            index === 3? setAmount4(item.value):'',
            index === 4? setAmount5(item.value):''
        ))
        setStackList(paresData);
        console.log('getStakeLabes : ',paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setStackListAPI = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
            "data": [
                {
                    "key": "100",
                    "value": amount1
                },
                {
                    "key": "500",
                    "value": amount2
                },
                {
                    "key": "1000",
                    "value": amount3
                },
                {
                    "key": "10000",
                    "value": amount4
                },
                {
                    "key": "50000",
                    "value": amount5
                }
            ]
        });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/updateStake`, requestOptions)
      .then(async (response) => {
        if(response.ok){
            let paresData = await response.json();
            setStackList(paresData);
            setIsUpdate(true);
            setTimeout(() => {
                setIsUpdate(false);
                handleClose();
            }, 2000)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Modal
        show={isopen}
        onHide={handleClose}
        className="menu-dropdown-popup stack-chnage-popup"
        dialogClassName="v-center"
      >
        <Modal.Header closeButton>
          <h2>
            {" "}
            <span className="title-v-border"></span> SET BUTTON STAKE
          </h2>
        </Modal.Header>
        <Modal.Body>
          {(
            <div className="stack-change-wraper">
              <div className="input-box-set">
                <label>Value</label>
                <div className="input-box">
                  <input
                    type="text"
                    onChange={(e) => setAmount1(e.target.value)}
                    value={amount1}
                  />
                </div>
                <div className="input-box">
                  <input
                    type="text"
                    onChange={(e) => setAmount2(e.target.value)}
                    value={amount2}
                  />
                </div>
                <div className="input-box">
                  <input
                    type="text"
                    onChange={(e) => setAmount3(e.target.value)}
                    value={amount3}
                  />
                </div>
                <div className="input-box">
                  <input
                    type="text"
                    onChange={(e) => setAmount4(e.target.value)}
                    value={amount4}
                  />
                </div>
                <div className="input-box">
                  <input
                    type="text"
                    onChange={(e) => setAmount5(e.target.value)}
                    value={amount5}
                  />
                </div>
              </div>
              <div className="btn-sec">
                <button className="btn" onClick={setStackListAPI}>Save</button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Popup
        popupClass={`alertMsgpopup ${isUpdate ? "open" : ""}`}
        content={
          <div className={`alert-msg success`}>
            <span className="msg">Stack Update Successfully!!</span>
          </div>
        }
      />

      <Popup
        popupClass={`alertMsgpopup ${isError ? "open" : ""}`}
        content={
          <div className={`alert-msg err`}>
            <span className="msg">{errorMsg}</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />
    </>
  );
}

export default StackChnage;
