import React, { useState, useEffect,useRef, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Container from "../../components/common/Container";
import { Utils } from "../../Utils";
import { useNavigate } from "react-router-dom";
import { PopupContext } from "../../components/LoginPopup/LoginPopupContext";

export default function Mybets() {
    const initialized = useRef(false);
    const token = sessionStorage.getItem("token");
    const [betDetails, setBetDetails] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectedTypes, setSelectedTypes] = useState('All Bets');
    const [hasMore, setHasMore] = useState(true);
    const [totalBetsCount, setTotalBetsCount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const navigate = useNavigate();
  const { loggedIn } = useContext(PopupContext);
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedValue = sessionStorage.getItem('loggedIn');
    return storedValue === "true";
  });
  
  useEffect(()=>{
    if(!isLoggedIn || loggedIn){
        navigate('/')
    }
},[isLoggedIn, loggedIn])

    const currentDate = new Date();
    const minToDate = fromDate ? new Date(fromDate.getTime() + 24 * 60 * 60 * 1000) : null;

    const formatDatefromAPI = (isoDate) => {
        const date = new Date(isoDate);

        const optionsDate = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        };

        const optionsTime = {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true, // This ensures the time is in 12-hour format with AM/PM
        };

        const formattedDate = date.toLocaleDateString('en-US', optionsDate);
        const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

        return `${formattedDate} at ${formattedTime}`;
      };

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <input
          style={{ cursor: "pointer" }}
          onClick={onClick}
          value={value}
          ref={ref}
          readOnly
          placeholder={Utils.getDateFormated}
        />
      ));

      const handleTypeChange = (event) => {
        setHasMore(true);
        setBetDetails([]);
        setCurrentPage(0);
        setSelectedTypes(event.target.value);
        callMyBets(formatDate(fromDate),formatDate(toDate),event.target.value, 0)
      };

      const callMyBets =(fromDate, toDate, type, page)=>{
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/Account/getBetDetails?fromDate=${fromDate}&toDate=${toDate}&type=${type}&page=${page}`, requestOptions)
        .then(async (response) => {
            let paresData = await response.json();
            setTotalAmount(paresData.data.amount);
            setTotalBetsCount(paresData.data.count);

            if (paresData.data.mybets.length === 0) {
                setHasMore(false);
              } else {
                setBetDetails(prevData => [...prevData, ...paresData.data.mybets]);
              }
              if (!response.ok) {

              }
            console.log("getBetDetails - ", paresData);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      const handleNextPage = () => {
        setCurrentPage(currentPage+1)
        callMyBets(formatDate(fromDate),formatDate(toDate),selectedTypes,currentPage+1);
      }

      useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            callMyBets(formatDate(fromDate),formatDate(toDate),selectedTypes,currentPage);
          }
      });

      const formatDate = (date) => {
        if (!date) return "";
        return date.toLocaleDateString("en-CA");
      };

  return (
    <Container size='full'>
      <div className="account-sec">
          <div className="acount-header-filter">
            <div className="title-wraper">
                <span className="v-border"></span>
                <h3 className="title">CURRENT BETS</h3>
            </div>
            <div className="right-filter">
            <div className="bet-amount">
              <span> Total Bets : <strong>{`${totalBetsCount}`}</strong></span>
              <span> Total Amount : <strong className={`${totalAmount > 0 ? 'green-txt' : 'red-txt'}`}>{`${totalAmount}`}</strong></span>
            </div>
              <div className="input-box date-picker">
                <DatePicker
                  selected={fromDate}
                  onChange={(date) => {setHasMore(true); setBetDetails([]); setCurrentPage(0); setFromDate(date); callMyBets(formatDate(date),formatDate(toDate),selectedTypes,0)}}
                  dateFormat={Utils.getDateFormated}
                  customInput={<CustomInput />}
                  maxDate={currentDate}
                />
              </div>
              <div className="input-box date-picker">
                <DatePicker
                  selected={toDate}
                  onChange={(date) => {setHasMore(true); setBetDetails([]); setCurrentPage(0); setToDate(date); callMyBets(formatDate(fromDate),formatDate(date),selectedTypes,0)}}
                  dateFormat={Utils.getDateFormated}
                  customInput={<CustomInput />}
                  minDate={minToDate}
                  maxDate={currentDate}
                  disabled={!fromDate}
                />
              </div>
              <div className="input-box">
                <select id="number-dropdown" value={selectedTypes} onChange={handleTypeChange}>
                  <option value="All Bets">All Bets</option>
                  <option value="OPEN">Open</option>
                  <option value="CANCEL">Void</option>
                  <option value="WON">Won</option>
                  <option value="LOSS">Loss</option>
                </select>
              </div>
            </div>
          </div>
          <div className="account-table">
            <table border="1" cellPadding="10" cellSpacing="0">
            <thead>
                <tr>
                  <th>Sr. No</th>
                  <th className="date time">Date & Time</th>
                  <th>Sports</th>
                  <th className="event">Event</th>
                  <th className="match">Match</th>
                  <th>Market</th>
                  <th className="beton">Bet On</th>
                  <th>Bet Type</th>
                  <th>Odds</th>
                  <th>Status</th>
                  <th>Stake</th>
                  <th>Result</th>
                  <th>Returns</th>
                </tr>
              </thead>
              <tbody>
          {betDetails && betDetails.map((item,index)=>(
              <tr key={index} className={`account-statment-expand ${item.bettype2 === 'BACK' ? 'back' : 'lay'}`}>
                  <td>{index+1}</td>
                  <td>{formatDatefromAPI(item.date)}</td>
                  <td>{item.betType}</td>
                  <td>{item.event}</td>
                  <td>{item.match}</td>
                  <td>{item.marketName}</td>
                  <td>{item.selectionName}</td>
                  <td>{item.bettype2}</td>
                  <td>{item.oddValue}</td>
                  <td>{item.status}</td>
                  <td>{item.Stake}</td>
                  <td>{item.result === '' ||item.result === null ? '-':item.result }</td>
                  <td className={`${item.returns > 0 ? 'green-txt' : 'red-txt'}`}>{item.status === 'OPEN' ? '-':item.returns}</td>
              </tr>
          ))}
          </tbody>
            </table>
            {hasMore ? <div className="load-more-btn-sec"> <button onClick={handleNextPage} className="btn">Load More</button> </div> :''}
        </div>
      </div>
    </Container>
  )
}
