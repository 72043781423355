import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider-reusable.css'
import { Link } from 'react-router-dom';

function SliderReusable({topSliderItem, settings, className} ) {
  return (
    <Slider {...settings} className={className}>
     {topSliderItem?.map((item, index) => (
     <Link to={`${item.url}`} key={index} className="slide">
        <img src={`https://admin.jerambro.com/banner/${item.bannerName}.webp`} />
     </Link>
     ))}
    </Slider>
  )
}

export default SliderReusable