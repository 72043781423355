import React from 'react'
import './scoreboard.css'
import MobileLiveStreamTab from './MobileLiveStreamTab';

function Scoreboard({scoreboardData, receivedBetlistData}) {
  const spId = scoreboardData?.sportId;
  const evId = scoreboardData?.eventId;
  const competitionName = scoreboardData?.competitionName;
  const eventName = scoreboardData?.eventName;
  const eventDate = scoreboardData?.openDate;
  const matchStatus = scoreboardData?.status;


  return (
    <div className='live-score'>
      <div className="heading">
        <div className="heading-text sport-name"><span className='series-name'>{competitionName}</span> <span className='seprator'>/</span> <strong>{eventName}</strong></div>
        <div className="heading-text time"><i className="fa-regular fa-clock"></i> {eventDate && (
          <>
            {new Date(eventDate).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            })}
            {" at "}
            {new Date(eventDate).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </>
        )}</div></div>
      {matchStatus == 'IN_PLAY' ? <div className='scoreboard'>
        <iframe src={`https://supermaster.live/scorecardifram?sportId=${spId}&eventId=${evId}`} ></iframe>
      </div> : ''}
      <MobileLiveStreamTab spId={spId} evId={evId} receivedBetlistData= {receivedBetlistData}/>
    </div>
  )
}

export default Scoreboard