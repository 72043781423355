import { Routes, Route } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/variables.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import Header from "./components/common/header/Header";
import Home from "./pages/Home/Home";
import Footer from "./components/common/footer/Footer";
import Exchange from "./pages/exchange/Exchange";
import SideSportMenu from "./components/sidesportmenu/SideSportMenu";
import NotFound from "./components/common/NotFound";
import Inplay from "./pages/inplay/Inplay";
import ExchangeInner from "./pages/exchangeinner/ExchangeInner";
import Cricket from "./pages/cricket/Cricket";
import Football from "./pages/football/Football";
import Tennis from "./pages/tennis/Tennis";
import LiveCasino from "./pages/live_casino/LiveCasino";
import Live_casinoInPlay from "./pages/Live_casinoInPlay/live_casinoInPlay";
import Royal_casino from "./pages/royal_casino/Royal_casino";
import Virtuals from "./pages/virtuals/Virtuals";
import Slot from "./pages/slots/Slot";
import Premium_sports from "./pages/premium_sports/Premium_sports";
import Profile from "./pages/profile/Profile";
import MyAccountStatment from "./pages/myAccountStatment/MyAccountStatment";
import MyPlStatement from "./pages/myPlStatement/MyPlStatement";
import MyPlStatementEvent from "./pages/myPlStatement/MyPlStatementEvent";
import MyPlStatementMatch from "./pages/myPlStatement/MyPlStatementMatch";
import Mybets from "./pages/mybets/Mybets";
import MyGameReport from "./pages/myGameReport/MyGameReport";
import MyGameReportEvent from "./pages/myGameReport/MyGameReportEvent";
import MyGameReportMatch from "./pages/myGameReport/MyGameReportMatch";
import MobileBottomMenu from "./components/common/mobilebottommenu/MobileBottomMenu";
import MyProfile from "./pages/myprofile/MyProfile";

export default function ChildApp() {
    return (
      <>
        <Header/>
        <main className="main-content">
          <SideSportMenu />
          <Routes>
            <Route path="/*" element={<NotFound />} />
            <Route path="/" element={<Home />} />
            <Route path="/exchange" element={<Exchange />} />
            <Route path="/exchange/inplay" element={<Inplay />} />
            <Route path="/exchange/cricket" element={<Cricket />} />
            <Route path="/exchange/football" element={<Football />} />
            <Route path="/exchange/tennis" element={<Tennis />} />
            <Route path="/exchange_inPlay/:eventId" element={<ExchangeInner />} />
            <Route path="/live_casino" element={<LiveCasino />} />
            <Route path="/Royal_casino" element={<Royal_casino />} />
            <Route path="/virtuals" element={<Virtuals />} />
            <Route path="/slots" element={<Slot />} />
            <Route path="/premium_sports" element={<Premium_sports />} />
            <Route path="/live_casinoInPlay/:gameId" element={<Live_casinoInPlay />} />

            <Route path="/profile" element={<Profile />} />
            <Route path="/myAccountStatment" element={<MyAccountStatment />} />
            <Route path="/MyPlStatement" element={<MyPlStatement />} />
            <Route path="/MyPlStatement/:eventname" element={<MyPlStatementEvent />} />
            <Route path="/MyPlStatement/:eventname/:matchname" element={<MyPlStatementMatch />} />
            <Route path="/mybets" element={<Mybets />} />
            <Route path="/myGameReport" element={<MyGameReport />} />
            <Route path="/myGameReport/:eventname" element={<MyGameReportEvent />} />
            <Route path="/myGameReport/:eventname/:matchname" element={<MyGameReportMatch />} />
            <Route path="/myprofile" element={<MyProfile />} />
          </Routes>
        </main>
        <Footer />
        <MobileBottomMenu />
      </>
    );
  }
