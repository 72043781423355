import { UserProvider } from "./UserContext";
import { LoginPopupProvider } from "./components/LoginPopup/LoginPopupContext";
import ChildApp from "./ChildApp";
import LoginPopup from "./components/LoginPopup/LoginPopup";
import { BrowserRouter } from "react-router-dom";

function App() {

  return (
    <div className="App">
      <UserProvider>
        <LoginPopupProvider>
          <BrowserRouter>
            <LoginPopup />
            <ChildApp />
          </BrowserRouter>
        </LoginPopupProvider>
      </UserProvider>
    </div>
  );
}

export default App;
