import React, { useState } from "react";
import Popup from "../common/popup/Popup";
import { Modal } from "react-bootstrap";

function ChangePasswordPopup({ isopen, handleClose,isPasswordChange }) {
  const [showPassword, setShowPassword] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confPasswordShow, setConfPasswordShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const token = sessionStorage.getItem("token");

  const togglePasswordVisibility = (type) => {
    if (type === "old") {
      setShowPassword(!showPassword);
    } else if (type === "new") {
      setNewPasswordShow(!newPasswordShow);
    } else {
      setConfPasswordShow(!confPasswordShow);
    }
  };

  const callChangePassword = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      oldPass: oldPassword,
      newPass: newPassword,
      confPass: confirmPassword,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/users/updateCurrentUserPass`,
      requestOptions
    )
      .then(async (response) => {
        let paresData = await response.json();
        if (paresData.status === "Error") {
          setIsError(true);
          setErrorMsg(paresData.message);
          setErrorDisable();
        } else {
          if(isPasswordChange){
            sessionStorage.setItem('passwordchanged',false)
            setIsUpdate(true);
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
            handleClose();
          }
          setIsUpdate(true);
          setOldPassword('');
          setNewPassword('');
          setConfirmPassword('');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setErrorDisable = () => {
    setTimeout(() => {
      setIsError(false);
    }, 2000);
  };

  const handleChangePass = () => {
    if (!oldPassword) {
      setIsError(true);
      setErrorMsg("Old password is required");
      setErrorDisable();
      return;
    }
    if (!newPassword) {
      setIsError(true);
      setErrorMsg("New password is required");
      setErrorDisable();
      return;
    }
    if (!confirmPassword) {
      setIsError(true);
      setErrorMsg("Confirm password is required");
      setErrorDisable();
      return;
    }
    if (newPassword !== confirmPassword) {
      setIsError(true);
      setErrorMsg("New password and confirm password do not match");
      setErrorDisable();
      return;
    }
    setIsError(false);
    setErrorMsg("");
    callChangePassword();
  };

  const handleCLOSE = () => {
    if(!isPasswordChange || sessionStorage.getItem('passwordchanged') === 'false'){
      handleClose();
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    }
  }

  return (
    <>
      <Modal
        show={isopen}
        onHide={handleCLOSE}
        className="menu-dropdown-popup change-psw-popup"
        dialogClassName="v-center"
      >
        <Modal.Header closeButton>
          <h2>
            <span className="title-v-border"></span> Change Password
          </h2>
        </Modal.Header>
        <Modal.Body>

          <div className="change-psw-wraper">
            <div className="input-box">
              <label>Enter Old Password</label>
              <div className="input-w-icon">
                <input
                  type={showPassword ? "text" : "password"}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  autoComplete="off"
                />
                <span
                  className="input-icon"
                  onClick={() => togglePasswordVisibility("old")}
                >
                  <i
                    className={`fa-solid ${
                      showPassword ? "fa-eye" : "fa-eye-slash"
                    }`}
                  ></i>
                </span>
              </div>
            </div>
            <div className="input-box">
              <label>Enter New Password</label>
              <div className="input-w-icon">
                <input
                  type={newPasswordShow ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  autoComplete="off"
                />
                <span
                  className="input-icon"
                  onClick={() => togglePasswordVisibility("new")}
                >
                  <i
                    className={`fa-solid ${
                      newPasswordShow ? "fa-eye" : "fa-eye-slash"
                    }`}
                  ></i>
                </span>
              </div>
            </div>
            <div className="input-box">
              <label>Confirm New Password</label>
              <div className="input-w-icon">
                <input
                  type={confPasswordShow ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  autoComplete="off"
                />
                <span
                  className="input-icon"
                  onClick={() => togglePasswordVisibility("conf")}
                >
                  <i
                    className={`fa-solid ${
                      confPasswordShow ? "fa-eye" : "fa-eye-slash"
                    }`}
                  ></i>
                </span>
              </div>
            </div>
            <div className="btn-sec">
              <button onClick={handleChangePass} className="btn">
                UPDATE CHANGES
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Popup
        popupClass={`alertMsgpopup ${isUpdate ? "open" : ""}`}
        content={
          <div className={`alert-msg success`}>
            <span className="msg">Password Change Successfully!!</span>
          </div>
        }
      />
      <Popup
        popupClass={`alertMsgpopup ${isError ? "open" : ""}`}
        content={
          <div className={`alert-msg err`}>
            <span className="msg">{errorMsg}</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />
      {isUpdate
        ? setTimeout(() => {
            setIsUpdate(false);
            handleCLOSE();
          }, 2000)
        : ""}
    </>
  );
}

export default ChangePasswordPopup;
