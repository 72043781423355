import React from 'react'
import './popup.css'
function Popup({content, popupClass}) {
    return (
        <div className={`popup ${popupClass}`}>
            <div className='popup-content'>
                {content}
            </div>
        </div>
    )
}

export default Popup