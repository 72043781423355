// PopupContext.js
import React, { createContext, useState } from 'react';

export const PopupContext = createContext();

export const LoginPopupProvider = ({ children }) => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [direactsecId, setSecId] = useState('');
  const [betSlipOpen, setBetSlipOpen] = useState(false);
  const [isBetUpdate, setIsBetUpdate] = useState(false);
  const [openDialog, setOpenDialog] = useState('');
  const [isBetLoader, setBetLoader] = useState(false);
  const [loaderCounter, setLoaderCounter] = useState(0);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);
  const isLoggedIn = () => setLoggedIn(true);
  const isLoggedOut = () => setLoggedIn(false);
  const clearSecId = () => setSecId('');
  const setSecIds = (id) => setSecId(id);
  const openBetSlip = () => setBetSlipOpen(true);
  const closeBetSlip = () => setBetSlipOpen(false);
  const betUpdated = () => setIsBetUpdate(true);
  const betNotUpdated = () => setIsBetUpdate(false);
  const openDialogSet = (id) => setOpenDialog(id);
  const clearDialog = () => setOpenDialog('');
  const setBetLoder = () => setBetLoader(true);
  const unsetBetLoader = () => setBetLoader(false);
  const loaderCounting = (time) => setLoaderCounter(time);


  return (
    <PopupContext.Provider value={{
      isPopupOpen,
      openPopup,
      closePopup,
      loggedIn,
      isLoggedIn,
      isLoggedOut,
      setSecIds,
      direactsecId,
      clearSecId,
      openBetSlip,
      closeBetSlip,
      betSlipOpen,
      betUpdated,
      betNotUpdated,
      isBetUpdate,
      clearDialog,
      openDialogSet,
      openDialog,
      setBetLoder,
      unsetBetLoader,
      isBetLoader,
      loaderCounting,
      loaderCounter
      }}>
      {children}
    </PopupContext.Provider>
  );
};
