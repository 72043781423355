import React, { useContext, useEffect, useState } from 'react'
import useSocket from '../../Websoket.js';
import './matchdata.css'
import { PopupContext } from '../LoginPopup/LoginPopupContext.js';
import betslipclosearrow from '../../assets/images/betslip-close-arrow.png'
import ExchanngeinfoPopup from './ExchanngeinfoPopup.js';

function MatchOdds({ eventAccess, getMatchOdds, onBetClick,getUnderOver }) {
    const matchOdds = {
        "eventId": eventAccess?.matchData?.eventId,
        "marketIds": [
            eventAccess?.matchoddId
        ]
    };
    const items = Array(4).fill(null); 
    // console.log(getUnderOver, 'MMMMMMMMMMMMMMMMMMMMMMMMMMMMM');
    
    
    const isOneClicked = sessionStorage.getItem('isOneClicked')
    let oneClickValue
    if(isOneClicked == 'true'){
        oneClickValue = sessionStorage.getItem('oneClickAmount')
    }

    const [odds, setOdds] = useState(matchOdds);
    const [slideToggle, setSlideToggle] = useState(false)
    const { isConnected, receivedMatchodds,receivedunderOve0,receivedunderOve1,receivedunderOve2,receivedunderOve3,receivedBookData,receivedunderOvebook, sendMessage, receivedcashoutMO } = useSocket();
    const [priceGet, setPriceGet] = useState();
    const [plusMinusValue, setplusMinusValue] = useState();
    const [bklyClassValue, setBklyClass] = useState();
    const [oddsValue, setoddsValue] = useState();
    const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('loggedIn'));
    const { openPopup, loggedIn, isLoggedOut, direactsecId, setSecIds,clearSecId,isBetUpdate,betNotUpdated,isBetLoader, loaderCounter} = useContext(PopupContext);
    const [disable, setDisable] = useState(false);
    const max_odd = receivedMatchodds?.finalResult?.[0]?.max_odd
    const [checkBet, setCheckBet] = useState();
    const [grulesPopup, setGrulesPopup] = useState();
    const [Beton, setBeton] = useState();
    const [perticularprofit, setperticularprofit] = useState();

    const escapeSelectorId = (id) => {
        if(id){
            return String(id).replace(/([#.,+*~':"!^$[\]()=>|\\/])/g, '\\$1');
        }
    };

    const gruleshandleClose = ()=>{
        setGrulesPopup(false);
    }
    const grulesHandle = ()=>{
        setGrulesPopup(true);
    }

    useEffect(() => {
        // Call cashOuthandle3 when receivedMatchodds updates and is valid
        if (Object.keys(receivedcashoutMO).length > 0 && receivedcashoutMO.secId) {
            cashOuthandle3();
        }
    }, [receivedcashoutMO]); // Dependency array: this runs whenever receivedMatchodds changes

    // console.log(receivedunderOve0, eventAccess, 'eventAccess');
    
    const toggleHandle2 = (secId, bklyClass, odds, Stake) => {
        setSlideToggle(slideToggle === secId ? secId : secId);
        setBklyClass(bklyClass);
        setoddsValue(odds);
        setPriceGet(Stake);
        setSecIds(secId)
    };

    const cashOuthandle3 = () => {
        let type = receivedcashoutMO.betType === "LAY" ? 'lay' : 'back';
        toggleHandle2(receivedcashoutMO.secId, type, receivedcashoutMO.odds, receivedcashoutMO.stake);
    };

    const handleCashOut = () => {
        let marketId = receivedMatchodds?.finalResult?.[0]?.marketId;
        let userinfo = sessionStorage.getItem('userinfo');
        let eventID = receivedMatchodds?.finalResult?.[0]?.eventId;
        setSlideToggle(false)
        if (userinfo && eventID) {
            userinfo = JSON.parse(userinfo);
            sendMessage('cashOOut', { id: marketId, user: userinfo, eventID });
        }
    };



    const addValue = (newValue,event) => {
        const closestBetslip = event.target.closest('.data-with-betslip');
        if (closestBetslip) {
            const bookValueDiv = closestBetslip.querySelector('.book-value');
            if (bookValueDiv) {
                let value
                if(bookValueDiv.innerText == ''){
                    if(bklyClassValue === 'back'){
                        value = (newValue * oddsValue) - newValue
                    }else{
                        value = -((newValue * oddsValue) - newValue)
                    }
                }else{
                    let previousValue = bookValueDiv.innerText
                    if(bklyClassValue === 'back'){
                        value = parseFloat((newValue * oddsValue) - newValue) + parseFloat(previousValue)
                    }else{
                        value = previousValue - parseFloat((newValue * oddsValue) - newValue)
                    }
                }
                bookValueDiv.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                setperticularprofit(value)
                if(value > 0){
                    bookValueDiv.classList.remove('red-txt')
                    bookValueDiv.classList.add('green-txt')
                }else{
                    bookValueDiv.classList.remove('green-txt')
                    bookValueDiv.classList.add('red-txt')
                }
                const closestBetslip2 = event.target.closest('.match-list');
                const allBookValueDivs = closestBetslip2.querySelectorAll('.book-value');

                allBookValueDivs.forEach(div => {
                    if (div !== bookValueDiv) {
                        let value
                        if(div.innerText == ''){
                            if(bklyClassValue === 'back'){
                                value = -newValue
                            }else{
                                value = newValue
                            }
                        }else{
                            let previousValue = div.innerText
                            if(bklyClassValue === 'back'){
                                value = previousValue - newValue
                            }else{
                                value =   parseFloat(previousValue) + parseFloat(newValue)
                            }
                        }
                        div.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                        if(value > 0){
                            div.classList.remove('red-txt')
                            div.classList.add('green-txt')
                        }else{
                            div.classList.remove('green-txt')
                            div.classList.add('red-txt')
                        }

                    }
                });
            } else {
                console.log('.book-value div not found');
            }
        } else {
            console.log('.data-with-betslip not found');
        }



        setplusMinusValue(newValue)
        if (priceGet !== undefined) {
            setPriceGet(prevPrice => Number(prevPrice) + Number(newValue));
        } else {
            setPriceGet(newValue);
        }

    };

    const handleSendMarketBookDetails = () => {
        console.log(eventAccess, 'eventAccesseventAccess');
        
        let eventId =  eventAccess?.matchData?.eventId;
        let marketId = eventAccess?.matchoddId;
        let userinfo = sessionStorage.getItem('userinfo');
        if (userinfo && eventId && marketId) {

            userinfo = JSON.parse(userinfo);
            sendMessage('marketIdbookDetails', { eventId, marketId, user: userinfo });
            if(eventAccess?.matchData?.sportId == '1'){
                sendMessage('marketIdbookDetailsUnderOver', { eventId, user: userinfo });
            }
        } else if(!userinfo){
            sendMessage('marketIdbookDetails', { eventId, marketId });
            if(eventAccess?.matchData?.sportId == '1'){
                sendMessage('marketIdbookDetailsUnderOver', { eventId, user: userinfo });
            }
        }else{
            console.error('Missing eventId or marketId');
        }
    };

    useEffect(() => {
        setPreviousBookValues();
    }, [receivedBookData]);

    const setPreviousBookValues = () => {
        if (receivedBookData && receivedBookData.showData) {
            for (let i = 0; i < receivedBookData.showData.length; i++) {
                const originalId = receivedBookData.showData[i].runnerId;
                const escapedId = escapeSelectorId(originalId);
                const element = document.getElementById(`${escapedId}`);
                if (element) {
                    const mainDiv = element.querySelector('.book-value');
                    if (mainDiv) {
                        mainDiv.innerText = receivedBookData.showData[i].amount !== null && receivedBookData.showData[i].amount !== undefined &&  !isNaN(Number(receivedBookData.showData[i].amount)) ? Number(receivedBookData.showData[i].amount).toFixed(2) : 'Invalid data';
                        if (receivedBookData.showData[i].amount > 0) {
                            mainDiv.classList.remove('red-txt');
                            mainDiv.classList.add('green-txt');
                        } else {
                            mainDiv.classList.remove('green-txt');
                            mainDiv.classList.add('red-txt');
                        }
                    } else {
                        console.error(`.book-value not found for ID: #${escapedId}`);
                    }
                } else {
                    console.error(`Element with ID #${escapedId} not found. Original ID was: ${originalId}`);
                }
            }
            if (receivedBookData.showData.length == 0) {

                // Use the ID directly with getElementById
                let element = document.getElementById(receivedBookData.marketId);

                if (!element) {
                    // If element is still null, escape special characters for querySelector
                    const escapedId = receivedBookData.marketId.replace(/([.#$[\]])/g, '\\$1');

                    // Use querySelector with escaped ID
                    element = document.getElementById(`${escapedId}`);
                }


                if (element) {
                    let divs = element.querySelectorAll('.book-value');

                    divs.forEach(div => {
                        div.innerText = '';  // Clear the text inside each .book-value div
                    });
                } else {
                }
            }
        }
        if (receivedunderOvebook && receivedunderOvebook.showData) {
            for (let i = 0; i < receivedunderOvebook.showData.length; i++) {
                const originalId = receivedunderOvebook.showData[i].runnerId;
                const escapedId = escapeSelectorId(originalId);
                const element = document.getElementById(`${escapedId}`);
                if (element) {
                    const mainDiv = element.querySelector('.book-value');
                    if (mainDiv) {
                        mainDiv.innerText = receivedunderOvebook.showData[i].amount !== null && receivedunderOvebook.showData[i].amount !== undefined &&  !isNaN(Number(receivedunderOvebook.showData[i].amount)) ? Number(receivedunderOvebook.showData[i].amount).toFixed(2) : 'Invalid data';
                        if (receivedunderOvebook.showData[i].amount > 0) {
                            mainDiv.classList.remove('red-txt');
                            mainDiv.classList.add('green-txt');
                        } else {
                            mainDiv.classList.remove('green-txt');
                            mainDiv.classList.add('red-txt');
                        }
                    } else {
                        console.error(`.book-value not found for ID: #${escapedId}`);
                    }
                } else {
                    console.error(`Element with ID #${escapedId} not found. Original ID was: ${originalId}`);
                }
            }
            if (receivedunderOvebook.showData.length == 0) {

                let elements = document.querySelectorAll('.under-over');

                elements.forEach(element => {
                    let divs = element.querySelectorAll('.book-value');
                
                    divs.forEach(div => {
                        div.innerText = ''; 
                    });
                })
            }else{
                let elements = document.querySelectorAll('.under-over');
                // console.log(receivedunderOvebook);
                
                elements.forEach(element => {
                    if(receivedunderOvebook.showData[0]){
                        if(!receivedunderOvebook.showData.some(item => item.marketId == element.id)){
                            let divs = element.querySelectorAll('.book-value');
                
                            divs.forEach(div => {
                                div.innerText = ''; 
                            });
                        }
                    }else{
                        let divs = element.querySelectorAll('.book-value');
                
                        divs.forEach(div => {
                            div.innerText = ''; 
                        });
                    }
                })

            }
        }
    };

    const getBetSlipClasss = (oddItem) => {
        if (direactsecId === oddItem.runnerId) {
            if(oddItem.status !== 'ACTIVE'){
                clearSecId()
                return 'betslip-wraper'
            }else{
                return 'betslip-wraper open'
            }
        }
        return 'betslip-wraper';
      };

    useEffect(() => {
        if(!isBetLoader){
            handlecloseSlip();
        }
      }, [isBetLoader, loaderCounter]);

      useEffect(() => {
        if (direactsecId) {
          setPreviousBookValues();
        }
      }, [direactsecId]);

    const updateBook = (event, oldvalue, newvalue) => {
        const closestBetslip = event.target.closest('.data-with-betslip');
        if (closestBetslip) {
            const bookValueDiv = closestBetslip.querySelector('.book-value');
            if (bookValueDiv) {
                if(oldvalue < newvalue){
                    newvalue = newvalue - oldvalue
                    let value
                if(bookValueDiv.innerText === ''){
                    if(bklyClassValue === 'back'){
                        value = (newvalue * oddsValue) - newvalue
                    }else{
                        value = -((newvalue * oddsValue) - newvalue)
                    }
                }else{
                    let previousValue = bookValueDiv.innerText
                    if(bklyClassValue === 'back'){
                        value = parseFloat((newvalue * oddsValue)- newvalue) + parseFloat(previousValue)
                    }else{
                        value = previousValue - parseFloat((newvalue * oddsValue)- newvalue)
                    }
                }
                bookValueDiv.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                setperticularprofit(value)
                if(value > 0){
                    bookValueDiv.classList.remove('red-txt')
                    bookValueDiv.classList.add('green-txt')
                }else{
                    bookValueDiv.classList.remove('green-txt')
                    bookValueDiv.classList.add('red-txt')
                }
                const closestBetslip2 = event.target.closest('.match-list');
                const allBookValueDivs = closestBetslip2.querySelectorAll('.book-value');
                allBookValueDivs.forEach(div => {
                    if (div !== bookValueDiv) {
                        let value
                        if(div.innerText == ''){
                            if(bklyClassValue === 'back'){
                                value = -newvalue
                            }else{
                                value = +newvalue
                            }
                        }else{
                            let previousValue = div.innerText
                            if(bklyClassValue === 'back'){
                                value = previousValue - newvalue
                            }else{
                                value =  parseFloat(newvalue) + parseFloat(previousValue)
                            }
                        }
                        div.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                        if(value > 0){
                            div.classList.remove('red-txt')
                            div.classList.add('green-txt')
                        }else{
                            div.classList.remove('green-txt')
                            div.classList.add('red-txt')
                        }

                    }
                });
                }else{
                    let diff = Math.abs(newvalue - oldvalue)

                    const closestBetslip = event.target.closest('.data-with-betslip');
                    if (closestBetslip) {
                        const bookValueDiv = closestBetslip.querySelector('.book-value');
                        if (bookValueDiv) {
                            let value
                            if(bookValueDiv.innerText == ''){
                                if(bklyClassValue === 'back'){
                                    value = (newvalue * oddsValue)- newvalue
                                }else{
                                    value = -((newvalue * oddsValue)- newvalue)
                                }
                            }else{
                                let previousValue = bookValueDiv.innerText
                                if(bklyClassValue === 'back'){
                                    value = parseFloat(previousValue) - parseFloat((diff * oddsValue)- diff)
                                }else{
                                    value = parseFloat(previousValue) + parseFloat((diff * oddsValue)- diff)
                                }
                            }
                            bookValueDiv.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                            setperticularprofit(value)
                            if(value > 0){
                                bookValueDiv.classList.remove('red-txt')
                                bookValueDiv.classList.add('green-txt')
                            }else{
                                bookValueDiv.classList.remove('green-txt')
                                bookValueDiv.classList.add('red-txt')
                            }
                            const closestBetslip2 = event.target.closest('.match-list');
                            const allBookValueDivs = closestBetslip2.querySelectorAll('.book-value');
                            allBookValueDivs.forEach(div => {
                                if (div !== bookValueDiv) {
                                    let value
                                    if(div.innerText == ''){
                                        if(bklyClassValue === 'back'){
                                            value = -newvalue
                                        }else{
                                            value = newvalue
                                        }
                                    }else{
                                        let previousValue = div.innerText
                                        if(bklyClassValue === 'back'){
                                            value =  parseFloat(previousValue) + parseFloat(diff)
                                        }else{
                                            value =  parseFloat(previousValue) - parseFloat(diff)
                                        }
                                    }
                                    div.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                                    if(value > 0){
                                        div.classList.remove('red-txt')
                                        div.classList.add('green-txt')
                                    }else{
                                        div.classList.remove('green-txt')
                                        div.classList.add('red-txt')
                                    }

                                }
                            });
                        }
                    }
                }
            }
        }
    }


    const PlusValue = (event) => {
        const newValue =  plusMinusValue ? plusMinusValue : 100
        let oldValue = priceGet

        if (priceGet !== undefined) {
            setPriceGet(prevPrice => Number(prevPrice) + Number(newValue));
        } else {
            oldValue = 0
            setPriceGet(newValue);
        }
        let newValuestake =  parseFloat(oldValue) + parseFloat(newValue)

        updateBook(event, oldValue, newValuestake)
    };
    const subtractValue = (event) => {
        const valueToSubtract  =  plusMinusValue ? plusMinusValue : 100
        let oldValue = priceGet

        setPriceGet(prevPrice => {
            const currentPrice = prevPrice ? Number(prevPrice) : 0;
            const newPrice = currentPrice - valueToSubtract;
            return newPrice < 0 ? 0 : newPrice;
        });
        if(oldValue){
            let newValue = oldValue - valueToSubtract
            if(newValue > 0){

                updateBook(event, oldValue, newValue)
            }else{
                setPreviousBookValues()
            }
        }
    };

    const incrementValue = (increment, event) => {
        let oldValue = priceGet
        setPriceGet(prevPrice => {
            const currentPrice = prevPrice ? Number(prevPrice) : 0;
            return currentPrice + increment;
        });
        let newValue = parseFloat(oldValue + increment)

        updateBook(event, oldValue, newValue)
    };

    const removeLastDigit = (event) => {
        let oldValue = priceGet
        setPriceGet(prevPrice => {
            if (prevPrice !== undefined) {
                const newPrice = String(prevPrice).slice(0, -1);
                return newPrice === '' ? 0 : Number(newPrice);
            }
            return 0;
        });
        if(oldValue){
            let newValue = String(oldValue).slice(0, -1);
            updateBook(event, oldValue, newValue)
        }else{
            setPreviousBookValues()
        }
    };

    useEffect(() => {
        let intervalId;
        if (isConnected && odds) {
            sendMessage('matchOddsMarketIds', odds);
            intervalId = setInterval(() => {
                sendMessage('matchOddsMarketIds', odds);
                for(let i = 0; i < items.length; i++){
                    if(eventAccess?.[`overunder${i}Status`]){
                        let data = {eventId:eventAccess?.matchData?.eventId, marketIds:[eventAccess?.[`overunder${i}Id`]]}
                        sendMessage(`overundermarketId${i}`,data);
                    }
                }

                
            }, 5000);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };

    }, [isConnected, odds, sendMessage]);


    const onClickFunction = (runnerId, toggleclass, event, runnerName) =>{
        event.persist();
        setSecIds(runnerId);
        setSlideToggle(slideToggle === runnerId ? runnerId : runnerId);
        setBklyClass(toggleclass);
        const strongElement = event.target.tagName === 'SPAN'
            ? event.target.querySelector('strong').innerText
            : event.target.closest('span')?.querySelector('strong').innerText;
            setoddsValue(strongElement);
            setPriceGet(oneClickValue)
            onBetClick({ eventid: receivedMatchodds?.finalResult?.[0]?.eventId, marketId: receivedMatchodds?.finalResult?.[0].marketId, stake: oneClickValue*1, bklyClassValue:toggleclass, selectionName: runnerName, secId: runnerId, oddsValue:strongElement })
    }

    useEffect(() => {
        let intervalId;

        if(isBetUpdate){
            handleSendMarketBookDetails();
            betNotUpdated();
        }
        const userinfo = sessionStorage.getItem('userinfo');
        if (userinfo) {
            if (isConnected) {
                let check = 0
                setInterval(() => {
                    if(check < 3){
                        handleSendMarketBookDetails();
                        check ++
                    }else{
                        clearInterval(intervalId);
                    }


                }, 1000);
            }

        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isConnected, sendMessage,isBetUpdate]);


    const toggleHandle = (slideId, bklyClass, event, runnerName) => {
        const strongElement = event.target.tagName === 'SPAN'
            ? event.target.querySelector('strong').innerText
            : event.target.closest('span')?.querySelector('strong').innerText;
        setSlideToggle(slideToggle === slideId ? slideId : slideId);
        setBklyClass(bklyClass);
        setoddsValue(strongElement);
        setSecIds(slideId);
        setBeton(runnerName)
        setPriceGet(0)

    }

    const handlecloseSlip =() => {
        setSlideToggle(false);
        clearSecId();
        handleSendMarketBookDetails();
        setPriceGet(0)
    }

    useEffect(() => {
        if (getMatchOdds?.status !== 'OPEN' || getMatchOdds?.runners?.some(runner => runner.status !== "ACTIVE")) {
            setSlideToggle(false);
        }
    }, [getMatchOdds]);

    const formatNumber = (num) => {
        if (num >= 1000000000) {
          return (num / 1000000000).toFixed(2) + 'B'; // Billion
        } else if (num >= 1000000) {
          return (num / 1000000).toFixed(2) + 'M'; // Million
        } else if (num >= 1000) {
          return (num / 1000).toFixed(2) + 'K'; // Thousand
        } else {
          return num.toString(); // Less than 1000
        }
      };

      
     
    return (
        <>
            {eventAccess?.matchoddStatus ? <div className='sport-all-data inner-match-list'>
                <div className='match-wrapper'>
                    <div className='heading'>
                        <div className='heading-text sport-name'>
                            Match Odds <span className='rulespopup-btn' onClick={grulesHandle}><i className="fa-solid fa-circle-info"></i></span>
                        </div>
                        <div className='heading-text sport-name'>
                            Min : {receivedMatchodds?.finalResult?.[0]?.min_stake}, Max : {receivedMatchodds?.finalResult?.[0]?.max_stake}
                        </div>
                    </div>
                    <div className='heading match-heading'>
                        <div className='heading-text sport-name'>
                            Market
                        </div>
                        {(receivedBookData && receivedBookData.showData && receivedBookData.showData.length !== 0 && receivedMatchodds?.finalResult?.[0]?.runners.length === 2) ?
                            <div className='cashout-btn'>
                                <button onClick={handleCashOut} className='btn'>Cashout</button>
                            </div>
                            : null
                        }
                        <div className='odds-heading'>
                            <div className='heading-text box'>Back</div>
                            <div className='heading-text box'>Lay</div>
                        </div>
                    </div>
                    <div className='match-data'>
                        <div className='match-list' id={receivedMatchodds?.finalResult?.[0].marketId}>
                            {receivedMatchodds?.finalResult?.[0]?.runners.map((oddItem, index) => (
                                <div className='data-with-betslip' key={index} id={oddItem.runnerId}>
                                    <div className='match-info-row'>
                                        <div className='match-name'>{oddItem.runnerName}</div>
                                        <div className={`${(receivedBookData && receivedBookData.showData && receivedBookData.showData.length !== 0
                                            && receivedBookData.showData.find(item => item.runnerId === oddItem.runnerId)?.amount < 0)
                                            ? 'red-txt'
                                            : 'green-txt'} book-value`}>
                                        {receivedBookData && receivedBookData.showData && receivedBookData.showData.length !== 0
                                            ? (receivedBookData.showData.find(item => item.runnerId === oddItem.runnerId)?.amount || 0)
                                            : ''
                                        }
                                        </div>
                                        <div className='match-odds'>
                                        {getMatchOdds?.status !== 'OPEN' || getMatchOdds?.runners?.[index]?.status !== "ACTIVE" ? <div className='suspended'>Suspended</div> : ''}
                                            <div className='box'>
                                            {oddItem?.backPrices.slice().reverse().map((item, indexx) => (
                                                <span className={`blue ${max_odd < item.price ? 'disable' : ''} ${oddItem?.backPrices && item.price !== 0 && getMatchOdds?.runners?.[index]?.backPrices.slice().reverse()[indexx]?.price !== 0 ? '' : ' lock'}`} onClick={(event) => { handleSendMarketBookDetails(); if (isOneClicked === "true") {onClickFunction(oddItem.runnerId, 'back', event,oddItem.runnerName);} else {toggleHandle(oddItem.runnerId, 'back', event, oddItem.runnerName);}}}>
                                                    {oddItem?.backPrices && item.price !== 0 && getMatchOdds?.runners?.[index]?.backPrices.slice().reverse()[indexx]?.price !== 0 ?
                                                        <>
                                                            <strong>{getMatchOdds === null ? item.price : getMatchOdds?.runners?.[index]?.backPrices.slice().reverse()[indexx]?.price}</strong>
                                                            <small>
                                                                {getMatchOdds === null ? formatNumber(item.size) : formatNumber(getMatchOdds?.runners?.[index]?.backPrices.slice().reverse()[indexx]?.size)}
                                                            </small>
                                                        </> :
                                                        <i className="fa fa-lock"></i>}
                                                </span>
                                            ))}
                                            </div>

                                            <div className='box'>

                                            {oddItem?.layPrices?.map((item, indexx) => (
                                                <span className={`pink ${max_odd < item.price ? 'disable' : ''} ${oddItem?.layPrices && item.price !== 0 && getMatchOdds?.runners?.[index]?.layPrices?.[indexx]?.price !== 0 ? '' : ' lock'}`} onClick={(event) => { handleSendMarketBookDetails(); if (isOneClicked === "true") {onClickFunction(oddItem.runnerId, 'lay', event,oddItem.runnerName);} else {toggleHandle(oddItem.runnerId, 'lay', event, oddItem.runnerName);}}}>
                                                    {oddItem?.layPrices && item.price !== 0 && getMatchOdds?.runners?.[index]?.layPrices?.[indexx]?.price !== 0 ?
                                                        <>
                                                            <strong>{getMatchOdds === null ? item.price : getMatchOdds?.runners?.[index]?.layPrices?.[indexx]?.price}</strong>
                                                            <small>
                                                                {getMatchOdds === null ? formatNumber(item.size) : formatNumber(getMatchOdds?.runners?.[index]?.layPrices?.[indexx]?.size)}
                                                            </small>
                                                        </> :
                                                        <i className="fa fa-lock"></i>}
                                                </span>
                                            ))}
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div className={getBetSlipClasss(oddItem)}>
                                            <div className={`betslip ${direactsecId === oddItem.runnerId ? 'active' : ''} ${bklyClassValue}`}>

                                                <div className="close-btn" onClick={handlecloseSlip}>×</div>
                                                <div className="betslip-top">
                                                    <div className="bet-selection-name" >Bet on : {Beton} @{oddsValue}</div>
                                                    <div className="bet-profit"></div>
                                                    <div className="betslip-odds"> <strong>Odds</strong> <span className="blue-text">{oddsValue}</span> </div>
                                                    <div className="betslip-stake">
                                                        <strong>Stake</strong>
                                                        <div className="input-group">
                                                            <span className="minus" onClick={(event) => subtractValue(event)}>-</span>
                                                            <input type="text" value={priceGet} className="set-stake-form-input" placeholder="00" aria-label="Last name"
                                                                autofocus="" maxLength="8" />
                                                            <span className="plus" onClick={(event) => PlusValue(event)}>+</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="betslip-placebutton">
                                                    <div className="price-btns">
                                                        <span id="100" onClick={(event) => addValue('100',event)}>+100</span>
                                                        <span id="500" onClick={(event) => addValue('500',event)}>+500</span>
                                                        <span id="1000" onClick={(event) => addValue('1000',event)}>+1k</span>
                                                        <span id="10000" onClick={(event) => addValue('10000',event)}>+10k</span>
                                                        <span id="50000" onClick={(event) => addValue('50000',event)}>+50k</span>
                                                        <span id="100000" onClick={(event) => addValue('100000',event)}>+100k</span>
                                                        <span id="1000" className="MAX">MAX</span>
                                                    </div>
                                                    <div className="betplace-btn">
                                                        {isLoggedIn || loggedIn ? <button className="place-bet" onClick={(event) =>{
                                                            onBetClick({ eventid: receivedMatchodds?.finalResult?.[0]?.eventId, marketId: receivedMatchodds?.finalResult?.[0].marketId, stake: priceGet, bklyClassValue, selectionName: oddItem.runnerName, secId: oddItem.runnerId, oddsValue })
                                                        } }>Place Bet</button> : <button onClick={openPopup} className="login-btn">Login to continue</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`betslip_mobileview ${direactsecId === oddItem.runnerId ? 'active' : ''} ${bklyClassValue}`}>
                                                <div className="close-btn" onClick={handlecloseSlip}>×</div>
                                                <table className="eventdetails">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className='plus-minus-wraper'>
                                                                <div className='plus-minus'>
                                                                    <span>-</span>
                                                                    <input type="text" value={oddsValue} />
                                                                    <span>+</span>
                                                                </div>
                                                                <div className='plus-minus'>
                                                                    <span onClick={(event) => subtractValue(event)}>-</span>
                                                                    <input type="text" value={priceGet} />
                                                                    <span onClick={(event) => PlusValue(event)} >+</span>
                                                                </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="11" className='mbet-slip'>
                                                                <div className="mbet-slip-wraper">
                                                                    <div className="odds-value" onClick={(event) => addValue('100',event)} id="100">+100</div>
                                                                    <div className="odds-value" onClick={(event) => addValue('500',event)} id="500">+500</div>
                                                                    <div className="odds-value" onClick={(event) => addValue('1000',event)} id="1000">+1k</div>
                                                                    <div className="odds-value" onClick={(event) => addValue('10000',event)} id="10000">+10k</div>
                                                                    <div className="odds-value" onClick={(event) => addValue('50000',event)} id="50000">+50k</div>
                                                                    <div className="odds-value max" onClick={(event) => setPriceGet('10000',event)} id="10000">MAX</div>
                                                                    <div className="odds-value all" onClick={(event) => setPriceGet('98941.80',event)} id="98941.80">ALL IN</div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="numbering">
                                                            <td colspan="11">
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td><span onClick={(event) => incrementValue('1',event)} id="1">1</span></td>
                                                                            <td><span onClick={(event) => incrementValue('2',event)} id="2">2</span></td>
                                                                            <td><span onClick={(event) => incrementValue('3',event)} id="3">3</span></td>
                                                                            <td><span onClick={(event) => incrementValue('4',event)} id="4">4</span></td>
                                                                            <td><span onClick={(event) => incrementValue('5',event)} id="5">5</span></td>
                                                                            <td><span onClick={(event) => incrementValue('6',event)} id="6">6</span></td>
                                                                            <td rowspan="2"><span className="back-span" onClick={(event) => removeLastDigit(event)}><img alt="" src={betslipclosearrow}/></span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><span onClick={(event) => incrementValue('7',event)} id="7">7</span></td>
                                                                            <td><span onClick={(event) => incrementValue('8',event)} id="8">8</span></td>
                                                                            <td><span onClick={(event) => incrementValue('9',event)} id="9">9</span></td>
                                                                            <td><span onClick={(event) => incrementValue('0',event)} id="0">0</span></td>
                                                                            <td><span onClick={(event) => incrementValue('00',event)} id="double0">00</span></td>
                                                                            <td><span onClick={(event) => incrementValue('000',event)} id="dot">000</span></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr className="bet-btns">
                                                            <td colspan="11">
                                                                <div className="btn-wraper">
                                                                    <button className="btn cancel-bet" onClick={handlecloseSlip}>CANCEL</button>
                                                                    {isLoggedIn || loggedIn ? <button className="btn place-bet" onClick={(event) => {
                                                                        onBetClick({ eventid: receivedMatchodds?.finalResult?.[0]?.eventId, marketId: receivedMatchodds?.finalResult?.[0].marketId, stake: priceGet, bklyClassValue, selectionName: oddItem.runnerName, secId: oddItem.runnerId, oddsValue })
                                                                    }}>Place Bet</button> : <button onClick={openPopup} className="btn login-btn">Login to continue</button>}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        {isBetLoader ?
                                       <div className="loader-sec loading">
                                        Placing Your Bet, Please Wait <div className='loader-box'><span className='loading-number'>{String(loaderCounter).padStart(2, '0')}</span><span className='loader'></span></div>
                                        </div> : ''}
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>

                </div>
            </div> : ''}
            {items.map((_, i) => (
                    eventAccess?.[`overunder${i}Status`] ? (
                        <div key={i} className='sport-all-data inner-match-list'>
                        <div className='match-wrapper'>
                                <div className='heading'>
                                    <div className='heading-text sport-name'>
                                    {eval(`receivedunderOve${i}`)?.finalResult?.[0]?.marketName}  <span className='rulespopup-btn' onClick={grulesHandle}><i className="fa-solid fa-circle-info"></i></span>
                                    </div>
                                    <div className='heading-text sport-name'>
                                        Min : {eval(`receivedunderOve${i}`)?.finalResult?.[0]?.min_stake}, Max : {eval(`receivedunderOve${i}`)?.finalResult?.[0]?.max_stake}
                                    </div>
                                </div>
                                <div className='heading match-heading'>
                                    <div className='heading-text sport-name'>
                                        Market
                                    </div>
                                    {/* {(receivedBookData && receivedBookData.showData && receivedBookData.showData.length !== 0 && eval(`receivedunderOve${i}`)?.finalResult?.[0]?.runners.length === 2) ?
                                        <div className='cashout-btn'>
                                            <button onClick={handleCashOut} className='btn'>Cashout</button>
                                        </div>
                                        : null
                                    } */}
                                    <div className='odds-heading'>
                                        <div className='heading-text box'>Back</div>
                                        <div className='heading-text box'>Lay</div>
                                    </div>
                                </div>
                                <div className='match-data'>
                                    <div className='match-list under-over' id={eval(`receivedunderOve${i}`)?.finalResult?.[0].marketId}>
                                        {eval(`receivedunderOve${i}`)?.finalResult?.[0]?.runners.map((oddItem, index) => (
                                            <div className='data-with-betslip' key={index} id={oddItem.runnerId}>
                                                <div className='match-info-row'>
                                                    <div className='match-name'>{oddItem.runnerName}</div>
                                                    <div className={`${(receivedBookData && receivedBookData.showData && receivedBookData.showData.length !== 0
                                                        && receivedBookData.showData.find(item => item.runnerId === oddItem.runnerId)?.amount < 0)
                                                        ? 'red-txt'
                                                        : 'green-txt'} book-value`}>
                                                    {receivedBookData && receivedBookData.showData && receivedBookData.showData.length !== 0
                                                        ? (receivedBookData.showData.find(item => item.runnerId === oddItem.runnerId)?.amount || 0)
                                                        : ''
                                                    }
                                                    </div>
                                                    <div className='match-odds'>
                                                    {getUnderOver?.[i]?.status !== 'OPEN' || getUnderOver?.[i]?.runners?.[index]?.status !== "ACTIVE" ? <div className='suspended'>Suspended</div> : ''}
                                                        <div className='box'>
                                                        {oddItem?.backPrices.slice().reverse().map((item, indexx) => (
                                                            <span className={`blue ${max_odd < item.price ? 'disable' : ''} ${oddItem?.backPrices && item.price !== 0 && getUnderOver?.[i]?.runners?.[index]?.backPrices.slice().reverse()[indexx]?.price !== 0 ? '' : ' lock'}`} onClick={(event) => { handleSendMarketBookDetails(); if (isOneClicked === "true") {onClickFunction(oddItem.runnerId, 'back', event,oddItem.runnerName);} else {toggleHandle(oddItem.runnerId, 'back', event, oddItem.runnerName);}}}>
                                                                {oddItem?.backPrices && item.price !== 0 && getUnderOver?.[i]?.runners?.[index]?.backPrices.slice().reverse()[indexx]?.price !== 0 ?
                                                                    <>
                                                                        <strong>{getUnderOver?.[i] === null ? item.price : getUnderOver?.[i]?.runners?.[index]?.backPrices.slice().reverse()[indexx]?.price}</strong>
                                                                        <small>
                                                                            {getUnderOver?.[i] === null ? formatNumber(item.size) : formatNumber(getUnderOver?.[i]?.runners?.[index]?.backPrices.slice().reverse()[indexx]?.size)}
                                                                        </small>
                                                                    </> :
                                                                    <i className="fa fa-lock"></i>}
                                                            </span>
                                                        ))}
                                                        </div>

                                                        <div className='box'>

                                                        {oddItem?.layPrices?.map((item, indexx) => (
                                                            <span className={`pink ${max_odd < item.price ? 'disable' : ''} ${oddItem?.layPrices && item.price !== 0 && getUnderOver?.[i]?.runners?.[index]?.layPrices?.[indexx]?.price !== 0 ? '' : ' lock'}`} onClick={(event) => { handleSendMarketBookDetails(); if (isOneClicked === "true") {onClickFunction(oddItem.runnerId, 'lay', event,oddItem.runnerName);} else {toggleHandle(oddItem.runnerId, 'lay', event, oddItem.runnerName);}}}>
                                                                {oddItem?.layPrices && item.price !== 0 && getUnderOver?.[i]?.runners?.[index]?.layPrices?.[indexx]?.price !== 0 ?
                                                                    <>
                                                                        <strong>{getUnderOver?.[i] === null ? item.price : getUnderOver?.[i]?.runners?.[index]?.layPrices?.[indexx]?.price}</strong>
                                                                        <small>
                                                                            {getUnderOver?.[i] === null ? formatNumber(item.size) : formatNumber(getUnderOver?.[i]?.runners?.[index]?.layPrices?.[indexx]?.size)}
                                                                        </small>
                                                                    </> :
                                                                    <i className="fa fa-lock"></i>}
                                                            </span>
                                                        ))}
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={getBetSlipClasss(oddItem)}>
                                                        <div className={`betslip ${direactsecId === oddItem.runnerId ? 'active' : ''} ${bklyClassValue}`}>

                                                            <div className="close-btn" onClick={handlecloseSlip}>×</div>
                                                            <div className="betslip-top">
                                                                <div className="bet-selection-name" >Bet on : {Beton} @{oddsValue}</div>
                                                                <div className="bet-profit"></div>
                                                                <div className="betslip-odds"> <strong>Odds</strong> <span className="blue-text">{oddsValue}</span> </div>
                                                                <div className="betslip-stake">
                                                                    <strong>Stake</strong>
                                                                    <div className="input-group">
                                                                        <span className="minus" onClick={(event) => subtractValue(event)}>-</span>
                                                                        <input type="text" value={priceGet} className="set-stake-form-input" placeholder="00" aria-label="Last name"
                                                                            autofocus="" maxLength="8" />
                                                                        <span className="plus" onClick={(event) => PlusValue(event)}>+</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="betslip-placebutton">
                                                                <div className="price-btns">
                                                                    <span id="100" onClick={(event) => addValue('100',event)}>+100</span>
                                                                    <span id="500" onClick={(event) => addValue('500',event)}>+500</span>
                                                                    <span id="1000" onClick={(event) => addValue('1000',event)}>+1k</span>
                                                                    <span id="10000" onClick={(event) => addValue('10000',event)}>+10k</span>
                                                                    <span id="50000" onClick={(event) => addValue('50000',event)}>+50k</span>
                                                                    <span id="100000" onClick={(event) => addValue('100000',event)}>+100k</span>
                                                                    <span id="1000" className="MAX">MAX</span>
                                                                </div>
                                                                <div className="betplace-btn">
                                                                    {isLoggedIn || loggedIn ? <button className="place-bet" onClick={(event) =>{
                                                                        onBetClick({ eventid: eventAccess?.matchData?.eventId, marketId: eval(`receivedunderOve${i}`)?.finalResult?.[0].marketId, stake: priceGet, bklyClassValue, selectionName: oddItem.runnerName, secId: oddItem.runnerId, oddsValue })
                                                                    } }>Place Bet</button> : <button onClick={openPopup} className="login-btn">Login to continue</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`betslip_mobileview ${direactsecId === oddItem.runnerId ? 'active' : ''} ${bklyClassValue}`}>
                                                            <div className="close-btn" onClick={handlecloseSlip}>×</div>
                                                            <table className="eventdetails">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div className='plus-minus-wraper'>
                                                                            <div className='plus-minus'>
                                                                                <span>-</span>
                                                                                <input type="text" value={oddsValue} />
                                                                                <span>+</span>
                                                                            </div>
                                                                            <div className='plus-minus'>
                                                                                <span onClick={(event) => subtractValue(event)}>-</span>
                                                                                <input type="text" value={priceGet} />
                                                                                <span onClick={(event) => PlusValue(event)} >+</span>
                                                                            </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="11" className='mbet-slip'>
                                                                            <div className="mbet-slip-wraper">
                                                                                <div className="odds-value" onClick={(event) => addValue('100',event)} id="100">+100</div>
                                                                                <div className="odds-value" onClick={(event) => addValue('500',event)} id="500">+500</div>
                                                                                <div className="odds-value" onClick={(event) => addValue('1000',event)} id="1000">+1k</div>
                                                                                <div className="odds-value" onClick={(event) => addValue('10000',event)} id="10000">+10k</div>
                                                                                <div className="odds-value" onClick={(event) => addValue('50000',event)} id="50000">+50k</div>
                                                                                <div className="odds-value max" onClick={(event) => setPriceGet('10000',event)} id="10000">MAX</div>
                                                                                <div className="odds-value all" onClick={(event) => setPriceGet('98941.80',event)} id="98941.80">ALL IN</div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="numbering">
                                                                        <td colspan="11">
                                                                            <table>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td><span onClick={(event) => incrementValue('1',event)} id="1">1</span></td>
                                                                                        <td><span onClick={(event) => incrementValue('2',event)} id="2">2</span></td>
                                                                                        <td><span onClick={(event) => incrementValue('3',event)} id="3">3</span></td>
                                                                                        <td><span onClick={(event) => incrementValue('4',event)} id="4">4</span></td>
                                                                                        <td><span onClick={(event) => incrementValue('5',event)} id="5">5</span></td>
                                                                                        <td><span onClick={(event) => incrementValue('6',event)} id="6">6</span></td>
                                                                                        <td rowspan="2"><span className="back-span" onClick={(event) => removeLastDigit(event)}><img alt="" src={betslipclosearrow}/></span></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><span onClick={(event) => incrementValue('7',event)} id="7">7</span></td>
                                                                                        <td><span onClick={(event) => incrementValue('8',event)} id="8">8</span></td>
                                                                                        <td><span onClick={(event) => incrementValue('9',event)} id="9">9</span></td>
                                                                                        <td><span onClick={(event) => incrementValue('0',event)} id="0">0</span></td>
                                                                                        <td><span onClick={(event) => incrementValue('00',event)} id="double0">00</span></td>
                                                                                        <td><span onClick={(event) => incrementValue('000',event)} id="dot">000</span></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="bet-btns">
                                                                        <td colspan="11">
                                                                            <div className="btn-wraper">
                                                                                <button className="btn cancel-bet" onClick={handlecloseSlip}>CANCEL</button>
                                                                                {isLoggedIn || loggedIn ? <button className="btn place-bet" onClick={(event) => {
                                                                                    onBetClick({ eventid: eventAccess?.matchData?.eventId, marketId: eval(`receivedunderOve${i}`)?.finalResult?.[0].marketId, stake: priceGet, bklyClassValue, selectionName: oddItem.runnerName, secId: oddItem.runnerId, oddsValue })
                                                                                }}>Place Bet</button> : <button onClick={openPopup} className="btn login-btn">Login to continue</button>}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    {isBetLoader ?
                                                <div className="loader-sec loading">
                                                    Placing Your Bet, Please Wait <div className='loader-box'><span className='loading-number'>{String(loaderCounter).padStart(2, '0')}</span><span className='loader'></span></div>
                                                    </div> : ''}
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                </div>

                            </div>
                        </div>
                    ) : ''
                ))}
            
            <ExchanngeinfoPopup isOpen={grulesPopup} handleClose={gruleshandleClose} />
        </>
    )
}

export default MatchOdds