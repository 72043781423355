import React, { useContext, useEffect, useState } from 'react'
import Container from '../../components/common/Container'
import './userprofile.css'
import { Link, useNavigate } from 'react-router-dom';
import ChangePasswordPopup from '../../components/menudropdoenpopup/ChangePasswordPopup';
import { PopupContext } from '../../components/LoginPopup/LoginPopupContext';


export default function Profile() {
  const [userData, setUserData] =useState();
  const [changePassword, setChangePassword] = useState(false);
  const token = sessionStorage.getItem('token');

  const handleClose = () => {setChangePassword(false);}
  const handleShow = () => { setChangePassword(true); }

  // const navigate = useNavigate();
//   const { loggedIn } = useContext(PopupContext);
//   const [isLoggedIn, setIsLoggedIn] = useState(() => {
//     const storedValue = sessionStorage.getItem('loggedIn');
//     return storedValue === "true";
//   });
  
//   useEffect(()=>{
//     if(!isLoggedIn || loggedIn){
//         navigate('/')
//     }
// },[isLoggedIn, loggedIn])

const callProfile = () =>{
  const myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${token}`);

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};

fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/Account/getMyProfile`, requestOptions)
.then(async (response) => {
  let paresData = await response.json();
  setUserData(paresData)
})
.catch((error) => {
  console.log(error);
});
}

useEffect(() => {
  callProfile()
}, [])

function maskContact(contact) {
  // Ensure contact is treated as a string
  const contactStr = String(contact);
  const contactLength = contactStr.length;
  const halfLength = Math.floor(contactLength / 2);

  // Mask the first half with '*' and return the concatenated string
  return '*'.repeat(halfLength) + contactStr.slice(halfLength);
}

function maskEmail(email) {
  // Split the email into the part before and after '@'
  const [username, domain] = email.split('@');

  // Mask the entire username with '*'
  const maskedUsername = '*'.repeat(username.length);

  // Return the masked email
  return `${maskedUsername}@${domain}`;
}

  return (
    <Container size='full'>
      <div className='user-profile'>
        <div className='left-part'>
          <div className='box user-details'>
            <div className='title'>User Details</div>
            <div className='content'>
              <div className='user-icon'><img src='https://bigbull9exch.com/assets/img/user-profile/user-profile.png' /></div>
              <div className='username'>{userData && userData.user.userName}</div>
              <div className="user-info">
                <Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path d="M20 16C18.8 16 17.5 15.8 16.4 15.4H16.1C15.8 15.4 15.6 15.5 15.4 15.7L13.2 17.9C10.4 16.4 8 14.1 6.6 11.3L8.8 9.1C9.1 8.8 9.2 8.4 9 8.1C8.7 7 8.5 5.7 8.5 4.5C8.5 4 8 3.5 7.5 3.5H4C3.5 3.5 3 4 3 4.5C3 13.9 10.6 21.5 20 21.5C20.5 21.5 21 21 21 20.5V17C21 16.5 20.5 16 20 16ZM5 5.5H6.5C6.6 6.4 6.8 7.3 7 8.1L5.8 9.3C5.4 8.1 5.1 6.8 5 5.5ZM19 19.5C17.7 19.4 16.4 19.1 15.2 18.7L16.4 17.5C17.2 17.7 18.1 17.9 19 17.9V19.5Z" fill="#182EA6"></path>
                  </svg>
                  {userData && maskContact(userData.user.contact)}
                </Link>
                <Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" className="email_icon">
                    <path d="M7 9.5L10.75 12.5C11.1047 12.784 11.5456 12.9388 12 12.9388C12.4544 12.9388 12.8953 12.784 13.25 12.5L17 9.5M21 17.5V7.5C21 6.96957 20.7893 6.46086 20.4142 6.08579C20.0391 5.71071 19.5304 5.5 19 5.5H5C4.46957 5.5 3.96086 5.71071 3.58579 6.08579C3.21071 6.46086 3 6.96957 3 7.5V17.5C3 18.0304 3.21071 18.5391 3.58579 18.9142C3.96086 19.2893 4.46957 19.5 5 19.5H19C19.5304 19.5 20.0391 19.2893 20.4142 18.9142C20.7893 18.5391 21 18.0304 21 17.5Z" stroke="#182EA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                  {userData && maskEmail(userData.user.email)}
                </Link>

              </div>
              <div className='change-psw-link'>
                <Link onClick={handleShow}>Change Password </Link>
              </div>
            </div>
          </div>
          <div className='box bonus-description'>
            <div className='title'>Bonus Description</div>
            <div className='content'>
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
              </p>
            </div>
          </div>
        </div>
        <div className='right-part'>
          <div className='box statitics'>
            <div className='title'>Statitics</div>
            <div className='content'>
              <div className='profit-box-row'>
                <div className='profit-box'>
                  <p>Today profit <strong>{userData && userData.userProfileContent.totalReturns}</strong></p>
                </div><div className='profit-box'>
                  <p>Today profit in Sports <strong>{userData && userData.userProfileContent.SPORTPROFIT}</strong></p>
                </div><div className='profit-box'>
                  <p>Today profit in Casino <strong>{userData && userData.userProfileContent.CASINOPROFIT}</strong></p>
                </div>
              </div>
              <div className='profit-box-row'>
                <div className='profit-box'>
                  <p>Today total deposit <strong>₹ {userData && userData.todaytotalDeposite}</strong></p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <ChangePasswordPopup isopen={changePassword} handleClose={handleClose} />
    </Container>

  )
}
