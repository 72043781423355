import React from 'react'

function NotFound() {
  return (
    <div className='not-found'>
        <h1 style={{margin:'100px 50px', textAlign:'center'}}>Not Found 😂</h1>    
    </div>
  )
}

export default NotFound