import React from 'react'
import { Link } from 'react-router-dom'

function MatchNotFound() {
  return (
<div class="match-not-found">
            <h2><span></span>The match you are looking for is no more live</h2>
            <p>Opps! Please try again later</p>
            <div class="error-404-btn">
                    <Link to="/">Go back to home</Link>

            </div>
        </div>
  )
}

export default MatchNotFound