import React, { useEffect, useState } from 'react'
import FancySession from './FancySession.js';
import FancyWpMarket from './FancyWpMarket.js';
import FancyOddEven from './FancyOddEven.js';
import OnlyOver from './OnlyOver.js';
import ExchanngeinfoPopup from '../ExchanngeinfoPopup.js';

function Fancy({ eventAccess, getFancy, getfancyClick, receivedBetlistData  }) {
    const [tab, setTab] = useState('all');
    const [grulesPopup, setGrulesPopup] = useState();
    const gruleshandleClose = ()=>{
        setGrulesPopup(false);
    }
    const grulesHandle = ()=>{
        setGrulesPopup(true);
    }
    let categorizedData = {
        sessonData: [],
        onlyOver: [],
        W_P_Market: [],
        Odd_even: []
      };

      if (getFancy && getFancy.length !== 0) {
        getFancy.forEach(item => {
          switch (item.category) {
            case "OVERS":
              categorizedData.sessonData.push(item);
              break;
            case "SINGLE_OVER":
              categorizedData.onlyOver.push(item);
              break;
            case "BATSMAN":
              categorizedData.W_P_Market.push(item);
              break;
            case "ODD_EVEN":
              categorizedData.Odd_even.push(item);
              break;
            // Add more cases if needed for other categories
            default:
              break;
          }
        });
      }
      // console.log(receivedBetlistData, 'receivedBetlistData');

    return (
        <>
        {eventAccess?.sessionStatus === true
        || eventAccess?.onlyoverStatus === true
        || eventAccess?.wpmarketStatus === true
        || eventAccess?.oddeventStatus === true ? <div className='sport-all-data inner-match-list fancylist'>
            <div className='match-wrapper'>
                <div className='heading'>
                    <div className='heading-text sport-name'>
                        Fancy <span className='rulespopup-btn' onClick={grulesHandle}><i className="fa-solid fa-circle-info"></i></span>
                    </div>
                </div>
                <div className='fancytabs'>
                    <div className='fancytab-btns'>
                        <button className={tab === 'all' ? 'active' : ''} onClick={() => setTab('all')}>All</button>
                        {eventAccess?.sessionStatus?<button className={tab === 'session' ? 'active' : ''} onClick={() => setTab('session')}>Session</button>:'' }
                        {eventAccess?.onlyoverStatus?<button className={tab === 'onlyover' ? 'active' : ''} onClick={() => setTab('onlyover')}>Only Over</button>:'' }
                        {eventAccess?.wpmarketStatus?<button className={tab === 'wpmarket' ? 'active' : ''} onClick={() => setTab('wpmarket')}>W/P Market</button>:'' }
                        {eventAccess?.oddeventStatus?<button className={tab === 'oddevn' ? 'active' : ''} onClick={() => setTab('oddevn')}>Odd/Even</button>:'' }
                    </div>
                    <div className='fancytab-content'>
                        {tab === 'all' ?
                            <>
                                <div className={`fancytab ${tab === 'session' ? '' : 'all'}`}> <FancySession eventAccess={eventAccess} categorizedData={categorizedData?.sessonData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                                <div className={`fancytab ${tab === 'onlyover' ? '' : 'all'}`}> <OnlyOver eventAccess={eventAccess} categorizedData={categorizedData?.onlyOver} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                                <div className={`fancytab ${tab === 'wpmarket' ? '' : 'all'}`}> <FancyWpMarket eventAccess={eventAccess} categorizedData={categorizedData?.W_P_Market} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                                <div className={`fancytab ${tab === 'oddevn' ? '' : 'all'}`}> <FancyOddEven eventAccess={eventAccess} categorizedData={categorizedData?.Odd_even} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            </> : ''
                        }
                        {tab === 'session' ?
                            <>
                            <div className={`fancytab ${tab === 'session' ? '' : 'active'}`}> <FancySession eventAccess={eventAccess}  categorizedData={categorizedData?.sessonData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'onlyover' ? '' : 'hide'}`}> <OnlyOver eventAccess={eventAccess} categorizedData={categorizedData?.onlyOver} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'wpmarket' ? '' : 'hide'}`}> <FancyWpMarket eventAccess={eventAccess} categorizedData={categorizedData?.W_P_Market} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'oddevn' ? '' : 'hide'}`}> <FancyOddEven eventAccess={eventAccess} categorizedData={categorizedData?.Odd_even} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                        </> : ''
                        }
                        {tab === 'onlyover' ?
                            <>
                            <div className={`fancytab ${tab === 'session' ? '' : 'hide'}`}> <FancySession eventAccess={eventAccess} categorizedData={categorizedData?.sessonData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'onlyover' ? '' : 'active'}`}> <OnlyOver eventAccess={eventAccess} categorizedData={categorizedData?.onlyOver} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'wpmarket' ? '' : 'hide'}`}> <FancyWpMarket eventAccess={eventAccess} categorizedData={categorizedData?.W_P_Market} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'oddevn' ? '' : 'hide'}`}> <FancyOddEven eventAccess={eventAccess} categorizedData={categorizedData?.Odd_even} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                        </> : ''
                        }
                        {tab === 'wpmarket' ?
                            <>
                            <div className={`fancytab ${tab === 'session' ? '' : 'hide'}`}> <FancySession eventAccess={eventAccess} categorizedData={categorizedData?.sessonData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'onlyover' ? '' : 'hide'}`}> <OnlyOver eventAccess={eventAccess} categorizedData={categorizedData?.onlyOver} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'wpmarket' ? '' : 'active'}`}> <FancyWpMarket eventAccess={eventAccess} categorizedData={categorizedData?.W_P_Market} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'oddevn' ? '' : 'hide'}`}> <FancyOddEven eventAccess={eventAccess} categorizedData={categorizedData?.Odd_even} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                        </> : ''
                        }
                        {tab === 'oddevn' ?
                              <>
                              <div className={`fancytab ${tab === 'session' ? '' : 'hide'}`}> <FancySession eventAccess={eventAccess} categorizedData={categorizedData?.sessonData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                              <div className={`fancytab ${tab === 'onlyover' ? '' : 'hide'}`}> <OnlyOver eventAccess={eventAccess} categorizedData={categorizedData?.onlyOver} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                              <div className={`fancytab ${tab === 'wpmarket' ? '' : 'hide'}`}> <FancyWpMarket eventAccess={eventAccess} categorizedData={categorizedData?.W_P_Market} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                              <div className={`fancytab ${tab === 'oddevn' ? '' : 'active'}`}> <FancyOddEven eventAccess={eventAccess} categorizedData={categorizedData?.Odd_even} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                          </> : ''
                        }
                    </div>
                </div>
            </div>
        </div> : ''}
        <ExchanngeinfoPopup isOpen={grulesPopup} handleClose={gruleshandleClose} />
        </>
    )
}

export default Fancy