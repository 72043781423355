import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

export default function Live_casinoInPlay({ headerRef }) {
  const initialized = useRef(false);
  const { gameId } = useParams();
  const [liveCasinoApi, setLiveCasino] = useState();
  const token = sessionStorage.getItem("token");

  const callAPI = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/live_casinoInPlay?gameId=${gameId}`,
      requestOptions
    )
      .then(async (response) => {
        console.log("response code : ",response.status)
        let paresData = await response.json();
        if(response.ok){
          setLiveCasino(paresData);
        }
        console.log("live_casinoInPlay - ", paresData);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      callAPI();
    }
  }, [liveCasinoApi]);

  return (
    <>
      <div className="game-iframe-sec">
        {liveCasinoApi && <iframe
          src={liveCasinoApi.urldata.url} // Replace with the desired URL
          title={gameId}
        />}
      </div>
    </>
  );
}
