import React, { useState, useEffect, useRef, useContext } from "react";
import DatePicker from "react-datepicker";
import Container from "../../components/common/Container";
import "react-datepicker/dist/react-datepicker.css";
import "./account-data.css";
import { Utils } from "../../Utils";
import { useNavigate } from "react-router-dom";
import { PopupContext } from "../../components/LoginPopup/LoginPopupContext";

export default function MyAccountStatment() {
  const initialized = useRef(false);
  const token = sessionStorage.getItem("token");
  const [accountStatement, setAccountStatement] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedTypes, setSelectedTypes] = useState("All Reports");
  const [currentPage, setCurrentPage] = useState(0);
  const [skipValue, setSkipValue] = useState(0);

  const [hasMore, setHasMore] = useState(true);

  // const navigate = useNavigate();
  // const { loggedIn } = useContext(PopupContext);
  // const [isLoggedIn, setIsLoggedIn] = useState(() => {
  //   const storedValue = sessionStorage.getItem('loggedIn');
  //   return storedValue === "true";
  // });
  
  //   useEffect(()=>{
  //     const checkLoginStatus = async () => {
  //       const sessionLoggedIn = sessionStorage.getItem('loggedIn');
  //     console.log("session Login : ",sessionStorage.getItem('loggedIn'))
  //     console.log("loggedIn : ",loggedIn)
  //       if(sessionLoggedIn === "true" && !loggedIn){
  //         console.log('11111111111111')
  //           navigate('/')
  //       }else{
  //         console.log('2222222222222')
  //       }
  //     }
  //     checkLoginStatus()
  //   },[isLoggedIn, loggedIn])

  const currentDate = new Date();
  const minToDate = fromDate
    ? new Date(fromDate.getTime() + 24 * 60 * 60 * 1000)
    : null;

  const toggleRow = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle the state for the clicked row
    }));
  };

  const formatDate = (date) => {
    if (!date) return "";
    return date.toLocaleDateString("en-CA");
  };

  const handleTypeChange = (event) => {
    setCurrentPage(0);
    setAccountStatement([])
    setHasMore(true);
    callAccStatement(fromDate, toDate, event.target.value, 0);
    setSelectedTypes(event.target.value);
  };

  const formatDatefromAPI = (isoDate) => {
    const date = new Date(isoDate);

    const optionsDate = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const optionsTime = {
      hour: "numeric",
      minute: "numeric",
      hour12: true, // This ensures the time is in 12-hour format with AM/PM
    };

    const formattedDate = date.toLocaleDateString("en-US", optionsDate);
    const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

    return `${formattedDate} at ${formattedTime}`;
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      style={{ cursor: "pointer" }}
      onClick={onClick}
      value={value}
      ref={ref}
      readOnly
      placeholder={Utils.getDateFormated}
    />
  ));

  const handleLoadMore =()=>{
    setCurrentPage(currentPage+1)
    callAccStatement(fromDate, toDate,selectedTypes, currentPage+1);
  }

  const downloadPDF = async (url,type) => {
    const currentDate = new Date();
    const options = { 
      year: 'numeric', 
      month: '2-digit', 
      day: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit', 
      hour12: false, // Use 24-hour format
    };
    
    // Format date and time according to local timezone
    const dateString = currentDate.toLocaleString('en-US', options)
      .replace(/,/g, '') // Remove commas
      .replace(/\//g, '-') // Replace slashes with dashes
      .replace(/ /g, '_'); // Replace spaces with underscores

    let name = ""
    if(type === 'csv'){
      name = `AccountStatement_${dateString}.csv`
    }else{
      name = `AccountStatement_${dateString}.pdf`
    }
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const blob = await response.blob(); // Convert the response to a Blob
      const link = document.createElement('a'); // Create a new link element
      link.href = window.URL.createObjectURL(blob); // Create an object URL for the Blob
      link.download = `${name}`; // Set the file name
      link.click(); // Programmatically click the link to trigger the download
      window.URL.revokeObjectURL(link.href); // Clean up the URL object
    } catch (error) {
      console.error('There was a problem with the download:', error);
    }
  };

  const callAccStatement = (fromDate, toDate, selectedTypes,currentPage) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    let raw = null;
    if (selectedTypes === "") {
      raw = JSON.stringify({
        page: currentPage,
        skipid: skipValue,
        fromDate: formatDate(fromDate),
        toDate: formatDate(toDate),
      });
    } else {
      raw = JSON.stringify({
        page: currentPage,
        skipid: skipValue,
        fromDate: formatDate(fromDate),
        toDate: formatDate(toDate),
        type: selectedTypes,
      });
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/Account/getMyAccStatement`,
      requestOptions
    )
      .then(async (response) => {
        let paresData = await response.json();
        if (paresData.userAcc.length === 0) {
          setHasMore(false);
        }else{
          setAccountStatement(prevData => [...prevData, ...paresData.userAcc]);
        }
        setSkipValue(paresData.skipvalue);       
        console.log("getMyAccStatement - ", paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      callAccStatement(fromDate, toDate, selectedTypes,currentPage);
    }
  });

  const handlePdfDownload = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization",`Bearer ${token}`);

    const raw = JSON.stringify({
      page: currentPage,
      fromDate: formatDate(fromDate),
      toDate: formatDate(toDate),
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/Account/getaccountStatmentpdf`,requestOptions)
    .then(async (response) => {
      let paresData = await response.json();
      if(paresData.status === 'success'){
        let filePath = `${process.env.REACT_APP_BASE_URL}${paresData.filepath}`
        downloadPDF(filePath,'pdf')
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const handleCsvDownload = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization",`Bearer ${token}`);

    const raw = JSON.stringify({
      page: currentPage,
      fromDate: formatDate(fromDate),
      toDate: formatDate(toDate),
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/Account/getaccountStatmencsv`,requestOptions)
    .then(async (response) => {
      let paresData = await response.json();
      if(paresData.status === 'success'){
        let filePath = `${process.env.REACT_APP_BASE_URL}${paresData.filepath}`
        downloadPDF(filePath,'csv')
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };

  return (
    <Container size="full">
      <div className="account-sec">
        <div className="acount-header-filter">
          <div className="title-wraper">
            <span className="v-border"></span>
            <h3 className="title">ACCOUNT STATEMENT</h3>
          </div>
          <div className="right-filter">
            <div className="input-box date-picker">
              <DatePicker
                selected={fromDate}
                onChange={(date) => {
                  setCurrentPage(0);
                  setFromDate(date);
                  setAccountStatement([])
                  setHasMore(true);
                  callAccStatement(date, toDate, selectedTypes,0);
                }}
                dateFormat={Utils.getDateFormated}
                customInput={<CustomInput />}
                maxDate={currentDate}
              />
            </div>
            <div className="input-box date-picker">
              <DatePicker
                selected={toDate}
                onChange={(date) => {
                  setToDate(date);
                  setCurrentPage(0);
                  setAccountStatement([])
                  setHasMore(true);
                  callAccStatement(fromDate, date, selectedTypes,0);
                }}
                dateFormat={Utils.getDateFormated}
                customInput={<CustomInput />}
                minDate={minToDate}
                maxDate={currentDate}
                disabled={!fromDate}
              />
            </div>
            <div className="input-box">
              <select
                id="number-dropdown"
                value={selectedTypes}
                onChange={handleTypeChange}
              >
                <option value="All Reports">All Reports</option>
                <option value="1">Deposit</option>
                <option value="2">Withdraw</option>
                <option value="3">Settlement Deposit</option>
                <option value="4">Settlement Withdraw</option>
                <option value="5">Bet settlement</option>
              </select>
            </div>
            <div className="btns">
              <button
                id="downloadBtn"
                onClick={handleCsvDownload}
                className="btn acount-stat-ro-titl-form-btnn load"
              >
                Download CSV
              </button>
              <button
                id="pdfDownload"
                onClick={handlePdfDownload}
                className="btn acount-stat-ro-titl-form-btnn load"
              >
                Print PDF
              </button>
            </div>
          </div>
        </div>

        <div className="account-table">
          <table
            border="1"
            cellPadding="10"
            cellSpacing="0"
            className="user-data-table"
          >
            <thead>
              <tr>
                <th width="238" className="transaction">
                  Transaction
                </th>
                <th width="322" className="date">
                  Date
                </th>
                <th width="255" className="event">
                  Event
                </th>
                <th width="194">Market Type</th>
                <th width="166">Credit/Debit</th>
                <th width="200">closing Balance</th>
                <th width="138">Action By</th>
                <th width="208">Transaction ID</th>
                <th width="84"></th>
                <th width="31"></th>
              </tr>
            </thead>
            <tbody>
              {accountStatement &&
                accountStatement.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr
                      onClick={() =>
                        item.TransactionType === "Bet Settlement"
                          ? toggleRow(index)
                          : ""
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <td>
                        {item.TransactionType == null ||
                        item.TransactionType === ""
                          ? "-"
                          : item.TransactionType}
                      </td>
                      <td>
                        {item.date == null || item.date === ""
                          ? "-"
                          : formatDatefromAPI(item.date)}
                      </td>
                      <td>
                        {item.match == null || item.match === ""
                          ? "-"
                          : item.match}
                      </td>
                      <td>
                        {item.marketName == null || item.marketName === ""
                          ? "-"
                          : item.marketName}
                      </td>
                      <td
                        className={`${
                          item.creditDebitamount > 0 ? "green-txt" : "red-txt"
                        }`}
                      >
                        {item.creditDebitamount == null ||
                        item.creditDebitamount === ""
                          ? "-"
                          : item.creditDebitamount}
                      </td>
                      <td>
                        {item.balance == null || item.balance === ""
                          ? "-"
                          : item.balance}
                      </td>
                      <td>
                        {item.loginuserName == null || item.loginuserName === ""
                          ? "-"
                          : item.loginuserName}
                      </td>
                      <td>
                        {item.transactionId == null || item.transactionId === ""
                          ? "-"
                          : item.transactionId}
                      </td>
                      <td></td>
                      <td
                        className={`${
                          item.TransactionType === "Bet Settlement"
                            ? "arrow"
                            : ""
                        }`}
                      ></td>
                    </tr>
                    {expandedRows[index] && (
                      <>
                        <tr className="address-header">
                          <td>Date</td>
                          <td>Event</td>
                          <td>Mrket Type</td>
                          <td>Bet On</td>
                          <td>Odds</td>
                          <td>Stake</td>
                          <td>Status</td>
                          <td>Result</td>
                          <td>Return</td>
                          <td></td>
                        </tr>
                        {accountStatement[index].thismarketbets.map(
                          (item, index) => (
                            <tr
                              key={index}
                              className={`account-statment-expand ${
                                item.bettype2 === "BACK" ? "back" : "lay"
                              }`}
                            >
                              <td>{formatDatefromAPI(item.date)}</td>
                              <td>{item.match}</td>
                              <td>{item.marketName}</td>
                              <td>{item.selectionName}</td>
                              <td>{item.oddValue}</td>
                              <td>{item.Stake}</td>
                              <td>{item.status}</td>
                              <td>{item.result}</td>
                              <td
                                className={`${
                                  item.returns > 0 ? "green-txt" : "red-txt"
                                }`}
                              >
                                {item.returns}
                              </td>
                              <td></td>
                            </tr>
                          )
                        )}
                      </>
                    )}
                  </React.Fragment>
                ))}
            </tbody>
          </table>
          {hasMore ? <div className="load-more-btn-sec">
            <button className="btn" onClick={handleLoadMore}>Load more</button>
          </div>:''}
        </div>
      </div>
    </Container>
  );
}
