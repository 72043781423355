import React from 'react'

function Container({className, size, children, ...rest}) {
    const containerSize = size === 'full' ? 'container-fluid' : 'container';
    const containerClassName = className ? `${containerSize} ${className}` : containerSize;
  return (
    <div className={containerClassName} {...rest}>
    {children}
  </div>
  )
}

export default Container