import { useEffect, useState, useCallback,useRef } from 'react';
import io from 'socket.io-client';

const SOCKET_SERVER_URL = 'api.supermaster.live/';
// const token = localStorage.getItem('authToken');  // Get token from localStorage

const Websoket = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [receivedMessages, setReceivedMessages] = useState({});
  const [receivedMatchodds, setReceivedMatchodds] = useState({});
  const [receivedBookmaker, setReceivedBookmaker] = useState({});
  const [receivedMnbookmaker, setReceivedMnbookmaker] = useState({});
  const [receivedFancysession, setReceivedFancysession] = useState({});
  const [receivedFancywpMarket, setReceivedFancywpMarket] = useState({});
  const [receivedFancyOddEven, setReceivedFancyOddEven] = useState({});
  const [receivedFancyOnlyOver, setReceivedFancyOnlyOver] = useState({});
  const [receivedBetlistData, setreceivedBetlistData] = useState({});
  const [receivedwalletData, setreceivedwalletData] = useState({});
  const [receivedBookData, setreceivedBookData] = useState({});
  const [receivedcashoutBm, setreceivedcashoutBm] = useState({});
  const [receivedcashoutMO, setreceivedcashoutMO] = useState({});
  const [receivedBookFancy, setreceivedBookFancy] = useState({});
  const [receivedToss, setReceivedToss] = useState({});
  const [receivedunderOve0, setReceivedunderOve0] = useState({});
  const [receivedunderOve1, setReceivedunderOve1] = useState({});
  const [receivedunderOve2, setReceivedunderOve2] = useState({});
  const [receivedunderOve3, setReceivedunderOve3] = useState({});
  const [receivedunderOvebook, setReceivedunderOvebook] = useState({});

  const [socket, setSocket] = useState(null);
  const [printmsg, setprintmsg] = useState();
  const socketRef = useRef(null);
  // let newSocket
  useEffect(() => {
    // if (!socketRef.current) {
      const  newSocket = io(SOCKET_SERVER_URL, {
        transports: ['websocket'],
        // reconnectionAttempts: 5, // Optional: limit reconnection attempts
        // reconnectionDelay: 1000, // Optional: delay between reconnection attempts
      });
    // }
      socketRef.current = newSocket
    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Websocket Connected');
      setIsConnected(true);
    });

    newSocket.on('connect_error', (err) => {
      console.error('Connection error:', err.message);
      setIsConnected(false);
    });

    newSocket.on('disconnect', () => {
      console.log('Socket disconnected');
      setIsConnected(false);
    });

    // Listen for messages dynamically
    const handleIncomingMessage = (event, callback) => {
      // console.log(event,'ytyatsyatsyatsyatsyatsyats')
      newSocket.on(event, (data) => {
        if(event === "inplaymatchdetails"){
            setReceivedMessages(data);
        }else if(event === "matchOddsMarketIds"){
          setReceivedMatchodds(data);
        }else if(event === "bookmakerMarketIds"){
          setReceivedBookmaker(data);
        }else if(event === "minibookmakerMarketIds"){
          setReceivedMnbookmaker(data);
        }else if(event === "sessionMarketIds"){
          setReceivedFancysession(data);
        }else if(event === "wpMarketIds"){
          setReceivedFancywpMarket(data);
        }else if(event === "oddEvenMarketIds"){
          setReceivedFancyOddEven(data);
        }else if(event === "onlyOverMarketIds"){
          setReceivedFancyOnlyOver(data);
        }else if(event === "getbetlistdata"){
          setreceivedBetlistData(data);
        }else if(event === "userLoginBalance"){
          setreceivedwalletData(data)
        }else if (event === "marketIdbookDetails"){
          setreceivedBookData(data)
        }else if(event === "cashOOutBm"){
          setreceivedcashoutBm(data)
        }else if(event === "cashOOut"){
          setreceivedcashoutMO(data)
        }else if(event === "getFancyBookDATAuserSide"){
          setreceivedBookFancy(data)
        } else if(event === "tossMarket"){
          setReceivedToss(data)
        } else if(event === "overundermarketId0"){
          setReceivedunderOve0(data)
        } else if(event === "overundermarketId1"){
          setReceivedunderOve1(data)
        } else if(event === "overundermarketId2"){
          setReceivedunderOve2(data)
        } else if(event === "overundermarketId3"){
          setReceivedunderOve3(data)
        } else if(event === "marketIdbookDetailsUnderOver"){
          setReceivedunderOvebook(data)
        } 
      });
    };

    setprintmsg(handleIncomingMessage)


    // Example event listeners
    handleIncomingMessage('inplaymatchdetails');
    handleIncomingMessage('matchOddsMarketIds');
    handleIncomingMessage('bookmakerMarketIds');
    handleIncomingMessage('minibookmakerMarketIds');
    handleIncomingMessage('sessionMarketIds');
    handleIncomingMessage('wpMarketIds');
    handleIncomingMessage('oddEvenMarketIds');
    handleIncomingMessage('onlyOverMarketIds');
    handleIncomingMessage('getbetlistdata');
    handleIncomingMessage('userLoginBalance');
    handleIncomingMessage('marketIdbookDetails');
    handleIncomingMessage('cashOOutBm');
    handleIncomingMessage('cashOOut');
    handleIncomingMessage('getFancyBookDATAuserSide');
    handleIncomingMessage('tossMarket');
    handleIncomingMessage('overundermarketId0');
    handleIncomingMessage('overundermarketId1');
    handleIncomingMessage('overundermarketId2');
    handleIncomingMessage('overundermarketId3');
    handleIncomingMessage('marketIdbookDetailsUnderOver');

    return () => {
      if (newSocket) newSocket.disconnect();
    };
  }, []);

  // Function to send messages to the server
  const sendMessage = useCallback((event, message) => {
    if (socket) {
      // console.log(`Sending message on ${event}:`, message);
      socket.emit(`${event}`, message);
    }
  }, [socket]);

  return { isConnected, receivedMessages,receivedToss, receivedMatchodds, receivedBookmaker, receivedMnbookmaker, receivedFancysession, receivedFancywpMarket, receivedFancyOddEven, receivedFancyOnlyOver, receivedBetlistData,receivedwalletData,receivedBookData,receivedcashoutBm,receivedcashoutMO,receivedBookFancy,receivedunderOve0,receivedunderOve1,receivedunderOve2,receivedunderOve3,receivedunderOvebook ,sendMessage};
};

export default Websoket;
