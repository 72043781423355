import React, { useState, useEffect, useRef, useContext } from "react";
import Container from '../../components/common/Container';
import "react-datepicker/dist/react-datepicker.css";
import { useParams, useNavigate } from "react-router-dom";
import { PopupContext } from "../../components/LoginPopup/LoginPopupContext";

export default function MyGameReportMatch() {
  const initialized = useRef(false);
  const token = sessionStorage.getItem("token");
  const [gameReport, setGameReport] = useState();
  const { eventname, matchname } = useParams();
  const navigate = useNavigate();
//   const { loggedIn } = useContext(PopupContext);
//   const [isLoggedIn, setIsLoggedIn] = useState(() => {
//     const storedValue = sessionStorage.getItem('loggedIn');
//     return storedValue === "true";
//   });
  
//   useEffect(()=>{
//     if(!isLoggedIn || loggedIn){
//         navigate('/')
//     }
// },[isLoggedIn, loggedIn])

  const formatDatefromAPI = (isoDate) => {
    const date = new Date(isoDate);

    const optionsDate = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    const optionsTime = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true, // This ensures the time is in 12-hour format with AM/PM
    };

    const formattedDate = date.toLocaleDateString('en-US', optionsDate);
    const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

    return `${formattedDate} at ${formattedTime}`;
  };

  const callGameReport = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/Account/getEventReport?eventName=${matchname}&compName=${eventname}`, requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        setGameReport(paresData);
        console.log("getGameReport - ", paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      callGameReport();
    }
  });

  return (
    <Container size='full'>
      <div className="account-sec">
      <div className="acount-header-filter">
        <div className="title-wraper">
          <h3 className="title" onClick={()=>navigate(-1)}><i className="fa-solid fa-arrow-left"></i>{eventname} | {matchname}</h3>
        </div>
      </div>

      <div className="account-table">
      <table border="1" cellPadding="10" cellSpacing="0">
        <thead>
          <tr>
            <th>Settlement Date</th>
            <th>Market</th>
            <th>Bet On</th>
            <th>Odds</th>
            <th>Status</th>
            <th>Stake</th>
            <th>Profit/Loss</th>
          </tr>
        </thead>
        <tbody>
          {gameReport && gameReport.data.map((item, index) => (
              <tr key={index}>
                <td>{formatDatefromAPI(item.settleDate)}</td>
                <td>{item.marketName}</td>
                <td>{item.selectionName}</td>
                <td>{item.oddValue}</td>
                <td>{item.status}</td>
                <td>{item.Stake}</td>
                <td className={`${item.returns > 0 ? 'green-txt' : 'red-txt'}`}>{item.returns}</td>
              </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
    </Container>
  )
}
