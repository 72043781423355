import React, { useEffect, useState } from 'react'
import Container from '../../components/common/Container'
import Banner from '../../components/common/Banner'
import { Link } from 'react-router-dom'
import Allsportdata from '../../components/allsportdata/Allsportdata'
import inPlaybanner from '../../assets/images/in_Playe_Page_Top.png'
import sidebarCard1 from '../../assets/images/cas-1.png'
import sidebarCard2 from '../../assets/images/cas-2.png'
import sidebarCard3 from '../../assets/images/cas-3.png'

function Tennis() {

  const [matchdata, setMatchData] = useState([]);

    useEffect(() => {
        // Fetch API data here
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/games/getExchangeData?type=tennis`);
                const data = await response.json();
                setMatchData(data);
            } catch (error) {
                console.error('Error fetching API data:', error);
            }
        };

        fetchData();
    }, []);

    console.log('inplay match', matchdata)

  return (
    <Container size='full'>
    <div className='content-w-sidebar'>
    <div className='left-content'>
    <Banner image={inPlaybanner}/>
    <Allsportdata matchData={matchdata} />
    </div>
    <div className='right-content sidebar'>
        <div className='sidebar-box'>
        <div className='title'>All Casino</div>
        <div className='sidebar-card'>
            <Link to='/'> <img src={sidebarCard1}/></Link>
            <Link to='/'> <img src={sidebarCard2}/></Link>
            <Link to='/'> <img src={sidebarCard3}/></Link>
        </div>
        </div>
    </div>
    </div>
</Container>
  )
}

export default Tennis