import React, { useEffect, useState } from "react";
import './hometab.css'
import HorseRacing from '../../assets/images/HorseRacing.svg'
import greyhound from '../../assets/images/greyhound.svg'
import Allsportdata from "../allsportdata/Allsportdata";
function HomeTab() {
    const [activeTab, setActiveTab] = useState("tab1"); // Initial active tab
    const [matchdata, setMatchData] = useState([]);
    const url = `${process.env.REACT_APP_BASE_URL}/api/v1/games/getExchangeData?type=`
    let endPoint = 'cricket';

    const handleTabClick = (tab) => {
        setActiveTab(tab); // Update the active tab on click

        if(tab === 'tab1'){
            endPoint = 'cricket';
        }else if(tab === 'tab2'){
            endPoint = 'football';
        }else if(tab === 'tab3'){
            endPoint = 'tennis';
        }
        fetchData()
    };

    const fetchData = async () => {
        try {
            const response = await fetch(`${url}${endPoint}`);
            const data = await response.json();
            setMatchData(data);
        } catch (error) {
            console.error('Error fetching API data:', error);
        }
    };

    useEffect(() => {
        // Fetch API data here
        fetchData();
    }, []);

    return (
        <div className="home-tab">
            <div className="tab-header">
                <button className={`tab-btn ${activeTab === "tab1" ? "active" : ""}`} onClick={() => handleTabClick("tab1")} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M15.8916 4.10831C14.32 2.59032 12.2149 1.75037 10.03 1.76936C7.84499 1.78834 5.75489 2.66475 4.20982 4.20982C2.66475 5.75489 1.78834 7.84499 1.76936 10.03C1.75037 12.2149 2.59032 14.32 4.10831 15.8916C5.67999 17.4096 7.78501 18.2496 9.96999 18.2306C12.155 18.2116 14.2451 17.3352 15.7901 15.7901C17.3352 14.2451 18.2116 12.155 18.2306 9.96999C18.2496 7.78501 17.4096 5.67999 15.8916 4.10831ZM5.28331 5.28331C6.22222 4.34401 7.42027 3.70655 8.72387 3.45266C10.0275 3.19877 11.3772 3.34003 12.6 3.85831L12.3583 4.10831L12.95 4.69998L13.3833 4.25831C13.6249 4.40409 13.8586 4.56265 14.0833 4.73331L4.73331 14.0833C4.56265 13.8586 4.40409 13.6249 4.25831 13.3833L4.69998 12.95L4.10831 12.3583L3.86664 12.6C3.34732 11.378 3.20472 10.0288 3.45712 8.7252C3.70951 7.42165 4.34541 6.22315 5.28331 5.28331ZM14.7166 14.7166C13.7777 15.6559 12.5797 16.2934 11.2761 16.5473C9.97248 16.8012 8.62276 16.6599 7.39998 16.1416L7.64164 15.8916L7.04998 15.3L6.61664 15.7416C6.37504 15.5959 6.14137 15.4373 5.91664 15.2666L15.2666 5.91664C15.4373 6.14137 15.5959 6.37504 15.7416 6.61664L15.3 7.04998L15.8916 7.64164L16.1333 7.39998C16.6526 8.62197 16.7952 9.9712 16.5428 11.2748C16.2904 12.5783 15.6545 13.7768 14.7166 14.7166ZM9.40831 12.95L9.99998 13.5333L8.82498 14.7166L8.23331 14.1666L9.40831 12.95ZM11.7666 10.5916L12.3583 11.175L11.175 12.3583L10.5916 11.7666L11.7666 10.5916ZM14.1666 8.23331L14.7583 8.82498L13.5333 9.99998L12.95 9.40831L14.1666 8.23331ZM10.5916 7.04998L9.99998 6.46664L11.175 5.28331L11.7666 5.87498L10.5916 7.04998ZM8.23331 9.40831L7.64164 8.82498L8.82498 7.64164L9.40831 8.23331L8.23331 9.40831ZM5.87498 11.7666L5.28331 11.175L6.46664 9.99998L7.04998 10.5916L5.87498 11.7666Z" fill="black"></path></svg>Cricket
                </button>

                <button className={`tab-btn ${activeTab === "tab2" ? "active" : ""}`}
                    onClick={() => handleTabClick("tab2")} id="footbal-li"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M10 1.875C12.1549 1.875 14.2215 2.73102 15.7452 4.25476C17.269 5.77849 18.125 7.84512 18.125 10C18.125 12.1549 17.269 14.2215 15.7452 15.7452C14.2215 17.269 12.1549 18.125 10 18.125C7.84512 18.125 5.77849 17.269 4.25476 15.7452C2.73102 14.2215 1.875 12.1549 1.875 10C1.875 7.84512 2.73102 5.77849 4.25476 4.25476C5.77849 2.73102 7.84512 1.875 10 1.875ZM8.545 3.27875L10 4.25L11.455 3.28C10.496 3.07321 9.50398 3.07321 8.545 3.28V3.27875ZM7.05375 3.78625C6.14171 4.22028 5.33608 4.84925 4.69375 5.62875L5.5025 8.25875L6.76 8.6775L9.375 6.69V5.335L7.05375 3.78625ZM3.81 7.00625C3.46445 7.71843 3.24535 8.48526 3.1625 9.2725L4.26875 8.49875L3.81 7.00625ZM3.17 10.7938C3.295 11.875 3.67 12.8813 4.23625 13.75H6.61625L7.41 12.9562L6.38125 9.86875L5.10125 9.4425L3.17 10.7925V10.7938ZM5.2825 15C5.78625 15.4775 6.36375 15.8787 6.995 16.185L6.47125 15H5.28375H5.2825ZM8.6075 16.7338C9.52621 16.9228 10.4738 16.9228 11.3925 16.7338L12.38 14.5138L11.6163 13.75H8.38375L7.62125 14.5125L8.6075 16.7338ZM13.0037 16.185C13.6322 15.8793 14.2105 15.4797 14.7187 15H13.5312L13.0037 16.185ZM15.7625 13.75C16.3125 12.9075 16.6825 11.9375 16.8175 10.8938L14.8875 9.44625L13.6188 9.86875L12.59 12.9562L13.3837 13.75H15.7625ZM16.845 9.3525C16.7689 8.53061 16.5444 7.72933 16.1825 6.9875L15.7238 8.5125L16.845 9.3525ZM15.29 5.60875C14.6503 4.83838 13.8505 4.21648 12.9463 3.78625L10.625 5.335V6.69L13.24 8.6775L14.495 8.26L15.29 5.60875ZM7.61 9.6L8.575 12.5H11.425L12.39 9.60125L10 7.785L7.61 9.6025V9.6Z" fill="black"></path></svg>Football</button>
                <button className={`tab-btn ${activeTab === "tab3" ? "active" : ""}`}
                    onClick={() => handleTabClick("tab3")} id="tennis-li"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.8925 15.8926C19.1467 12.6384 19.1467 7.36178 15.8925 4.10762C12.6383 0.853451 7.36166 0.853451 4.1075 4.10762C0.853329 7.36178 0.853329 12.6384 4.1075 15.8926C7.36166 19.1468 12.6383 19.1468 15.8925 15.8926ZM16.6192 9.20095C16.4423 7.7189 15.7712 6.33968 14.7142 5.28595C13.6565 4.22509 12.2712 3.55312 10.7833 3.37928L10.775 3.42595C10.4367 5.25035 9.55327 6.92933 8.24124 8.24136C6.9292 9.5534 5.25023 10.4368 3.42583 10.7751L3.37916 10.7835C3.553 12.2713 4.22496 13.6567 5.28583 14.7143C6.33956 15.7714 7.71878 16.4425 9.20083 16.6193L9.21083 16.5609C9.54896 14.7364 10.4323 13.0572 11.7444 11.745C13.0564 10.4328 14.7355 9.54929 16.56 9.21095L16.6183 9.20095H16.6192ZM16.56 11.191V10.9126C15.1828 11.2276 13.9223 11.9245 12.9234 12.9235C11.9244 13.9225 11.2274 15.1829 10.9125 16.5601H11.1908C12.5264 16.3195 13.7561 15.6753 14.7142 14.7143C15.6752 13.7562 16.3195 12.5265 16.56 11.191ZM3.42666 9.07428V8.88845C3.65543 7.52308 4.30549 6.26345 5.28583 5.28595C6.26325 4.30536 7.52288 3.655 8.88833 3.42595H9.07333C8.75839 4.80315 8.06142 6.06361 7.06246 7.06258C6.06349 8.06155 4.80303 8.75852 3.42583 9.07345L3.42666 9.07428Z" fill="black"></path></svg>Tennis</button>
                <button className={`tab-btn ${activeTab === "tab4" ? "active" : ""}`}
                    onClick={() => handleTabClick("tab4")} id="horse-li">
                        <img src={HorseRacing} />
                    Horse Racing
                </button>
                <button className={`tab-btn ${activeTab === "tab5" ? "active" : ""}`}
                    onClick={() => handleTabClick("tab5")} id="grey-li">
                    <img src={greyhound} />
                    GreyHound
                </button>
                <button className={`tab-btn ${activeTab === "tab6" ? "active" : ""}`}
                    onClick={() => handleTabClick("tab6")} id="basket-li"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.41489 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM4.6875 5.63828C5.56469 6.70018 6.0994 8.00304 6.2211 9.375H3.15391C3.27724 8.00343 3.81176 6.70105 4.6875 5.63828ZM10.625 9.375V3.15391C12.0262 3.28081 13.3542 3.8365 14.4281 4.74531C13.3174 6.04694 12.6503 7.66858 12.5234 9.375H10.625ZM9.375 9.375H7.47657C7.34975 7.66858 6.6826 6.04694 5.57188 4.74531C6.64585 3.8365 7.97384 3.28081 9.375 3.15391V9.375ZM6.2211 10.625C6.0994 11.997 5.56469 13.2998 4.6875 14.3617C3.81176 13.299 3.27724 11.9966 3.15391 10.625H6.2211ZM7.47657 10.625H9.375V16.8461C7.97384 16.7192 6.64585 16.1635 5.57188 15.2547C6.68246 13.953 7.34959 12.3314 7.47657 10.625ZM10.625 10.625H12.5234C12.6504 12.3314 13.3176 13.953 14.4281 15.2547C13.3542 16.1635 12.0262 16.7192 10.625 16.8461V10.625ZM13.7789 10.625H16.8461C16.7228 11.9966 16.1883 13.299 15.3125 14.3617C14.4353 13.2998 13.9006 11.997 13.7789 10.625ZM13.7789 9.375C13.9006 8.00304 14.4353 6.70018 15.3125 5.63828C16.1883 6.70105 16.7228 8.00343 16.8461 9.375H13.7789Z" fill="black"></path></svg>basketball</button>
                <button className={`tab-btn ${activeTab === "tab7" ? "active" : ""}`}
                    onClick={() => handleTabClick("tab7")} id="base-li"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M10 1.875C5.52 1.875 1.875 5.52 1.875 10C1.875 14.48 5.52 18.125 10 18.125C14.48 18.125 18.125 14.48 18.125 10C18.125 5.52 14.48 1.875 10 1.875ZM10 3.125C10.9196 3.12504 11.8297 3.31022 12.6761 3.6695C13.5226 4.02879 14.2881 4.55482 14.9269 5.21625C14.275 5.68822 13.7293 6.29161 13.325 6.9875L14.4 7.61875C14.7313 7.05232 15.1799 6.56326 15.7156 6.18438C16.4728 7.31273 16.8764 8.64116 16.875 10C16.875 11.4106 16.4469 12.7231 15.7156 13.8156C15.172 13.4297 14.7171 12.9322 14.3813 12.3563L13.3 12.9813C13.7106 13.689 14.2648 14.303 14.9269 14.7837C14.2881 15.4452 13.5226 15.9712 12.6761 16.3305C11.8297 16.6898 10.9196 16.875 10 16.875C9.08045 16.875 8.17032 16.6898 7.32386 16.3305C6.4774 15.9712 5.71195 15.4452 5.07312 14.7837C5.73543 14.303 6.28981 13.689 6.70062 12.9813L5.61938 12.3563C5.28339 12.9322 4.82824 13.4298 4.28438 13.8156C3.52722 12.6873 3.12358 11.3588 3.125 10C3.125 8.58938 3.55313 7.27688 4.28438 6.18438C4.8203 6.56301 5.26908 7.05185 5.60063 7.61813L6.675 6.98687C6.27064 6.29122 5.72494 5.68804 5.07312 5.21625C5.71195 4.55482 6.4774 4.02879 7.32386 3.6695C8.17032 3.31022 9.08045 3.12504 10 3.125ZM7.2375 8.25625L6.04375 8.625C6.18125 9.075 6.25 9.5375 6.25 10C6.25 10.4563 6.1875 10.9062 6.05625 11.3437L7.25 11.7C7.58696 10.5762 7.58261 9.3776 7.2375 8.25625ZM12.7625 8.25625C12.4174 9.3776 12.413 10.5762 12.75 11.7L13.9437 11.3437C13.8125 10.9062 13.75 10.4563 13.75 10C13.75 9.5375 13.8187 9.075 13.9562 8.625L12.7625 8.25625Z" fill="black"></path></svg>baseball</button>
            </div>
            <div className="tab-content">
                <div className={`tab-panel ${activeTab === "tab1" ? "active" : ""}`}>
                <Allsportdata  matchData={matchdata}/>
                </div>
                <div className={`tab-panel ${activeTab === "tab2" ? "active" : ""}`}><Allsportdata  matchData={matchdata}/></div>
                <div className={`tab-panel ${activeTab === "tab3" ? "active" : ""}`}><Allsportdata  matchData={matchdata}/></div>
                <div className={`tab-panel ${activeTab === "tab4" ? "active" : ""}`}>No Data</div>
                <div className={`tab-panel ${activeTab === "tab5" ? "active" : ""}`}>No Data</div>
                <div className={`tab-panel ${activeTab === "tab6" ? "active" : ""}`}>No Data</div>
                <div className={`tab-panel ${activeTab === "tab7" ? "active" : ""}`}>No Data</div>
            </div>
        </div>
    );
}

export default HomeTab;
