import React, { useEffect, useState, useRef } from 'react';
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

const OddsFeedComponent = ({ eventAccess, getMatchOddsHandle, getBookmakerHandle, getFancyHandle,getUnderOverHandle  }) => {
  const [connected, setConnected] = useState(false);
  const stompClientRef = useRef(null);
  const wsSubscriptions_OddsChanges = useRef({});
  const wsSubscriptions_SrBetStop = useRef({});
  const wsSecMarketSubscritions = useRef({});
  const reconnectAttempts = useRef(0);
  const [matchOdds, setMatchOdds] = useState(null);
  const [bookmaker, setBookmaker] = useState(null);
  const [underover, setUnderover] = useState([null, null, null, null]);
  const [fancy, setFancy] = useState(null);
  // console.log(underover, 'underoverunderoverunderover');
  

  useEffect(() => {
    getMatchOddsHandle(matchOdds);
  }, [matchOdds, getMatchOddsHandle]);

  useEffect(() => {
    getUnderOverHandle(underover);
  }, [underover, getUnderOverHandle]);

  useEffect(() => {
    getBookmakerHandle(bookmaker);
  }, [bookmaker, getBookmakerHandle]);

  useEffect(() => {
    getFancyHandle(fancy);
  }, [fancy, getFancyHandle]);

  const unsubscribeAllWsforEvents = () => {
    if (stompClientRef.current) {
      // Unsubscribe from Odds Changes
      for (const wsEvent of Object.keys(wsSubscriptions_OddsChanges.current)) {
        wsSubscriptions_OddsChanges.current[wsEvent].unsubscribe();
        console.log('Unsubscribed: ', wsEvent);
        delete wsSubscriptions_OddsChanges.current[wsEvent];
      }

      // Unsubscribe from SrBetStop
      for (const wsEvent of Object.keys(wsSubscriptions_SrBetStop.current)) {
        wsSubscriptions_SrBetStop.current[wsEvent].unsubscribe();
        console.log('Unsubscribed: ', wsEvent);
        delete wsSubscriptions_SrBetStop.current[wsEvent];
      }

      // Unsubscribe from SecMarket Subscriptions
      for (const wsEvent of Object.keys(wsSecMarketSubscritions.current)) {
        wsSecMarketSubscritions.current[wsEvent].unsubscribe();
        console.log('Unsubscribed: ', wsEvent);
        delete wsSecMarketSubscritions.current[wsEvent];
      }
    }
  };

  const connectWebSocket = (eventAccess) => {
    const socket = new SockJS('https://feed.mysportsfeed.io/odds-feed'); // Initialize SockJS connection
    const stompClient = new Client({
      webSocketFactory: () => socket,
      reconnectDelay: 30000, // Reconnect after 30 seconds if disconnected
      heartbeatIncoming: 10000, // Keep the connection alive with heartbeats
      heartbeatOutgoing: 10000,
      onConnect: () => {
        setConnected(true);
        reconnectAttempts.current = 0;
        console.log('Connected to WebSocket');

        // Subscribe to Bookmaker updates
        if (eventAccess?.bookmakerStatus && eventAccess?.bookmakerId) {
            const sub = stompClient.subscribe(
              `/topic/tommy_bm_update/${eventAccess?.matchData?.eventId}/${eventAccess?.bookmakerId}`,
              (message) => {
                const jsonData = JSON.parse(message.body);
                // console.log(jsonData, 'BMBMBMB');
                setBookmaker(jsonData);
              }
            );
            wsSubscriptions_OddsChanges.current[`ws-${eventAccess?.matchData?.eventId}-${eventAccess?.bookmakerId}`] = sub;
        }

        // Subscribe to Match Odds
        if (eventAccess?.matchoddStatus && eventAccess?.matchoddId) {
            const sub = stompClient.subscribe(
              `/topic/betfair_match_odds_update/${eventAccess?.matchData?.eventId}/${eventAccess?.matchoddId}`,
              (message) => {
                const jsonData = JSON.parse(message.body);
                // matchOdd(jsonData);
                // console.log(jsonData, 'MOMOMO');
                setMatchOdds(jsonData);
              }
            );
            wsSecMarketSubscritions.current[`bm-${eventAccess?.matchData?.eventId}-${eventAccess?.matchoddId}`] = sub;
        }

        for(let i = 0; i < 4; i++){
          if(eventAccess?.[`overunder${i}Status`] && eventAccess?.[`overunder${i}Id`]){
            const sub = stompClient.subscribe(
              `/topic/betfair_match_odds_update/${eventAccess?.matchData?.eventId}/${eventAccess?.[`overunder${i}Id`]}`,
              (message) => {
                const jsonData = JSON.parse(message.body);
                setUnderover(prevState => {
                  const newState = [...prevState]; 
                  newState[i] = jsonData; 
                  return newState; 
              });
                
              }
            );
            wsSecMarketSubscritions.current[`bm-${eventAccess?.matchData?.eventId}-${eventAccess?.[`overunder${i}Id`]}`] = sub;
        }
        }



        if (eventAccess?.matchData?.sportId === '4') {
          // console.log(eventAccess?.matchData?.sportId, 'eventAccess?.matchData?.sportIdeventAccess?.matchData?.sportId');
          const sub = stompClient.subscribe(
            `/topic/tommy_fancy_update/${eventAccess?.matchData?.eventId}`,
            (message) => {
              const jsonData = JSON.parse(message.body);
              setFancy(jsonData);

            }
          );
          wsSecMarketSubscritions.current[`fancy-${eventAccess?.matchData?.eventId}`] = sub;
        }
      },
      onStompError: (error) => {
        console.error('Stomp Error:', error);
      },
      onWebSocketClose: (error) => {
        console.error('Stomp Error:', error);
        setConnected(false);
        unsubscribeAllWsforEvents();
        if (reconnectAttempts.current < 5) {
          reconnectAttempts.current += 1;
          // console.log('Reconnecting...');
          connectWebSocket(eventAccess);
        } else {
          console.error('Failed to reconnect after 5 attempts.');
        }
      },
    });

    stompClientRef.current = stompClient;
    stompClient.activate(); // Establish the connection
  };

  useEffect(() => {
    if (eventAccess) {
      connectWebSocket(eventAccess); // Initialize WebSocket connection when eventAccess is available
    }

    return () => {
      // Cleanup WebSocket connection and unsubscribe on component unmount
      if (stompClientRef.current) {
        stompClientRef.current.deactivate();
        unsubscribeAllWsforEvents();
      }
    };
  }, [eventAccess]);
};

export default OddsFeedComponent;
